@use "sass:color";
@use "src/styles/config";

.card-container {
  display: flex;
  min-height: 125px;
  min-width: 265px;
  margin: 10px 0;
  border-radius: 4.16px !important;
  background-color: #FFFFFF !important;
  box-shadow: 0 0 6px 0 rgba(99,102,106,0.1), 0 3px 4px 0 rgba(99,102,106,0.14) !important;
}

.carousel-header-style {
  @include config.brand-header-sm;
  font-weight: normal !important;
}

.topic-text-container {
  padding: 10px 8px 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  flex: 1;
}

.image-style {
  object-fit: cover;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 96px;
}

.topic-style {
  @include config.brand-header-default;
  color: config.$brand-color-foreground-primary;
  text-align: left;
}

.topic-style:hover {
  visibility: visible;
}

.button-style {
  height: 40px;
  width: 8rem;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .card-container {
    min-width: 100px;
    min-height: 158px;
    flex-direction: column;
  }

  .topic-text-container {
    padding: 8px 12px;
  }
  .image-style {
    width: 100px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
  }

  .topic-style {
    @include config.brand-subtext-default-semibold;
    width: 100%;
  }

  .button-style {
    @include config.brand-subtext-default-regular;
    padding: 7px 0 8px;
    width: 80px;
    height: 33px;
    border-width: 2px;
  }
}
