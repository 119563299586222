@use "src/styles/config";

.provided-by-mock-vendor-container {
  margin: 0px auto;
  text-align: center;
}

.provided-by-text-style {
  @include config.brand-text-lg-regular;
  position: relative;
  bottom: -8px;
}

.mock-vendor-logo-style {
  width: 150px;
}

@media screen and (max-width: 767px) {
  .provided-by-mock-vendor-container {
    margin-top: 16px;
  }

  .provided-by-text-style {
    @include config.brand-subtext-default-regular;
    bottom: -4px;
  }

  .mock-vendor-logo-style {
    width: 100px;
  }
}
