.assessment-footer {
  padding: 15px 0;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 450px) {
  .assessment-footer {
    border-top: 2px solid rgba(211, 211, 211, 0.3);
  }
}
