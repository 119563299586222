@use "src/styles/config";

.container {
  display: flex;
  align-items: flex-start;
  padding-bottom: 5px;
}

.icon-style {
  background-color: config.$brand-color-primary;
  padding: 6px;
  height: 30px;
  width: 30px;
}
