@use "src/styles/config";

.info-section {
  background: config.$brand-color-background-neutral;
  border-radius: 8px;
  padding: 14px 20px 20px 10px;
  margin: auto;
  border-radius: 6px;
  max-width: 435px;
  text-align: left;
}

.info-text {
  @include config.brand-text-default-regular;
  position: relative;
  padding-left: 40px;
}

.info-text:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 25px;
  height: 25px;
  background: url(../../../assets/icons/security.svg) no-repeat center;
  background-size: contain;
}
