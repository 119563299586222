@use "src/styles/config";

.container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  text-align: center;
  background: config.$brand-home-container-desktop-background;
  background-size: contain, cover;
  color: #fff;
  margin: 0;
  text-shadow: 0 0 19px #00000053
}

.text {
  @include config.brand-text-lg-regular;
  width: 100%;
  padding: 6px 73px !important;
  text-align: center;
  color: #fff;
}

.text2 {
  @include config.brand-text-lg-regular;
  color: #ffffff;
  margin-bottom: 0.5em;
}

.play {
  width: 100px;
  height: 100px;
}

.button {
  @include config.brand-header-default;
  height: 56px;
  margin: auto;
  width: 260px;
  box-shadow: 3px 9px 20px 0 rgba(0, 0, 0, 0.4);
  color: config.$brand-color-secondary;
}

.bottom-content-container {
  position: absolute;
  bottom: 22vh;
}

.description-text-style {
  @include config.brand-header-default;
  font-weight: 600;
  position: absolute;
  color: #fff;
  top: 15%;
}

// to handle the app version container css for mobile and ipad devices.
@media screen and (min-width: config.$breakpoint-md) and (max-width: config.$breakpoint-lg) {
  .bottom-content-container {
    bottom: 15vh;
  }
}

@media screen and (max-width: config.$breakpoint-xl) {
  .container {
    position: fixed;
    overflow: hidden;
    touch-action: none;
    background: config.$brand-home-container-mobile-background;
    background-size: cover;
  }

  .play {
    width: 70px;
    height: 70px;
  }

  .button {
    box-shadow: 3px 9px 20px 0 rgba(0, 0, 0, 0.4);
    width: 80%;
  }

  .text {
    padding: 1vh 7px !important;
  }

  .description-text-style {
    position: relative;
    margin-top: 15px;
    top: 0;
  }
}

.sign-in-button {
  background-color: transparent;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.version-container {
  position: fixed;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid #fff;
}

.version-container p {
  @include config.brand-subtext-default-regular;
  color: #fff;
  padding: 2px;
}

.content-container {
  display: flex;
  height: 80%;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-content-container {
  width: 100%;
  display: flex;
  justify-self: center;
  flex-direction: column;
  align-items: center;
}

.powered-by-logo-container {
  margin: 10px;
  position: fixed;
  right: 1.5%;
  bottom: 3%;
}

.logo-style {
  width: 9rem;
  height: 2rem;
}

@media (min-width: config.$breakpoint-lg) {
  .description-text-style {
    width: 90%;
  }
}

@media (max-width: config.$breakpoint-sm) {
  .content-container {
    height: 100%;
  }

  .bottom-content-container {
    position: inherit;
  }
}
