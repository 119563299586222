@use "sass:map";
@use "src/styles/config";

.info-style {
  text-align: center;
}

.expansion-panel-container {
  margin: 8px 16px;
}

.expansion-panel-details-container {
  display: flex;
  flex-wrap: wrap;
}

.shadow-style {
  box-shadow: 3px 3px 15px 1px hsla(0, 4%, 89%, 0.7);
}

.categories-container {
  width: 100%;
}

.bonus-color {
  background-color: map.get(config.$color-goal-severity,bonus);
}

.high-color {
  background-color: map.get(config.$color-goal-severity,high);
}

.medium-color {
  background-color: map.get(config.$color-goal-severity,moderate);
}

.low-color {
  background-color: map.get(config.$color-goal-severity,low);
}
