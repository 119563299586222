@use "sass:color";
@use "src/styles/config";

.container {
  width: 80%;
  padding-top: 111px;
  position: fixed;
  background: #fff;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;
  display: none;
}

.container h2 {
  @include config.brand-text-default-regular;
  padding-left: 2px;
  padding-top: 17px;
  margin: 0;
}

.container li > div {
  list-style: none;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 12px 0 12px 21px;
}

.container li {
  margin: 8px 15px 0px 15px;
  list-style: none;
  border-radius: 3px;
}

.container ul {
  padding-left: 0;
  padding-bottom: 31px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  margin: 16px 0;
}

.left-side {
  width: 18px;
  height: 16px;
  margin-right: 21px;
}

.left-side img {
  width: 100%;
}

.top-star {
  position: absolute;
  top: 93px;
  right: 20px;
  background: url(../../assets/img/dashboard/starbak.png) no-repeat;
  background-size: 100% 100%;
  padding: 11px 6px;
}

.profile-name-style {
  color: config.$brand-color-foreground-primary !important;
}

.nav-title span {
  font-size: config.$brand-fs-subtext-default !important;
  font-weight: config.$brand-fw-semibold;
  display: inline-block;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  outline: inherit;
  text-align: right;
  background-color: #fff !important;
  white-space: nowrap;
  color: config.$brand-color-foreground-primary;
}

.nav-title:hover {
  color: config.$brand-color-primary;
}

.over-layout {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 103;
}

@media screen and (max-width: 1250px) {
  .nav-title {
    letter-spacing: 1px;
  }
}

@media (max-width: 768px) {
  .container {
    transform: translateX(-100%);
    transition: 0.5s;
    z-index: 10000;
    padding-top: 0;
    display: block;
  }

  .container.open-style {
    transform: translateX(0);
    box-shadow: 0px 0px 8px 0px rgba(113, 109, 109, 0.74);
  }

  .container h2 {
    padding-bottom: 15px;
  }

  .top-star {
    top: 0;
    padding: 6px 2px;
  }
}

.sidebar-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #000;
  padding-left: 19px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
  box-shadow: 0 2px 3px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.4);
  height: 115px;
}

.container .profile-name-style {
  margin-left: 10px;
  font-weight: config.$brand-fw-semibold;
}

.container .close-button {
  position: absolute;
  left: 105%;
  top: 2%;
}

.container .close-button-icon {
  font-size: 1.75rem;
  cursor: pointer;
  color: #fff;
}

.nav-title.active span {
  color: config.$brand-color-primary !important;
}
