@use "src/styles/config";

.button-style {
  @include config.brand-text-lg-semibold;
  text-transform: none;
  color: #3bb8c6;
  border: 2px solid #3bb8c6;
  margin-bottom: 6px;
  width: 100%;
  min-height: 42px;
}

.button-style:hover {
  background-color: #3bb8c6;
}

.alert-label-style {
  @include config.brand-subtext-default-semibold;
  color: config.$brand-color-alert;
  text-decoration: underline;
  text-align: center;
  margin-top: 32px;
}

.label-style {
  @include config.brand-subtext-default-semibold;
  color: #2b8345;
  text-align: center;
  margin-bottom: 6px;
}

@media (max-width: 768px) {
  .button-style {
    color: config.$brand-color-primary !important;
    font-size: 1rem;
  }
  .button-style:hover {
    color: #fff !important;
  }
}