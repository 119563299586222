.skeleton-wrapper {
  margin: 10px 20px;
  padding: 10px 20px 0 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .skeleton-wrapper {
    padding: 17px 10px 10px 10px;
  }

  .skeleton-wrapper {
    width: auto;
  }
}
