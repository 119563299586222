@use "src/styles/config";

.button-style {
  text-transform: none;
  margin-right: 4px;
  margin-bottom: 6px;
  color: #3bb8c6;
  border: 1px solid #3bb8c6;
  font-weight: config.$brand-fw-semibold;
}

.button-style:hover {
  background-color: #3bb8c6;
}

.selected-style {
  background-color: #3bb8c6;
  color: white;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 8px;
}


@media (max-width: 768px) {
  .button-style {
    color: config.$brand-color-primary !important;
    font-size: 1rem;
  }
  .button-style:hover {
    color: #fff !important;
  }
}
