@use "src/styles/config";

.container {
  @include config.brand-text-default-regular;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  background-color: config.$brand-color-background-primary-lighter;
  height: 100%;
}

.container .list-style {
  width: 100%;
  overflow: auto;
  padding-top: 20px;
}

.container .list-style-desktop {
  margin-bottom: 0;
}

.container .list-style-mobile-web {
  margin-bottom: 10px;
}

.loading-container {
  height: 40px;
  width: 100%;
  background-color: config.$brand-color-background-primary-lighter;
  position: absolute;
  top: 14px;
  right: 1px;
  z-index: 1;
}

.scroll-ref-style {
  margin-top: 20px;
}
