@use "sass:color";
@use "src/styles/config";

.container {
  border-radius: 5px;
}

.top-container {
  padding: 25px 30px 10px 30px;
  background-color: rgb(221, 243, 248);
}

.top-container h2 {
  @include config.brand-header-sm;
  text-align: center;
  margin: auto;
}

.top-container p {
  @include config.brand-subtext-default-regular;
  margin: 15px auto 0 auto;
  text-align: center;
  color: rgb(152, 159, 163);
}

.bottom-container {
  padding-top: 20px;
  padding-bottom: 10px;
}

.download-section-container p {
  @include config.brand-subtext-default-semibold;
}

.responsive-img-style {
  width: 100%;
}

.enter-phone-style {
  margin: auto;
}

.send-button-style {
  margin: 15px 0 0 10px;
  width: 80px;
}

.disclaimer-style {
  @include config.brand-subtext-default-regular;
  text-align: center;
  margin-top: 10px;
}

.input-style {
  margin-top: 15px;
}

@media screen and (max-width: 991px) {
  .enter-phone-style {
    text-align: center;
  }

  .download-section-container {
    text-align: center;
  }

  .send-button-style {
    margin: 25px auto 0 auto;
  }

  .download-now-button-style {
    margin: auto;
  }
}
