@use "src/styles/config";

.inactive-container,
.active-container {
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 10px;
}

.large-content-container, .small-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.small-content-container {
  height: 100%;
}

.active-container {
  border: 2px solid #03a0b5;
  border-radius: 10px;
}

.inactive-text, .active-text, .active-description, .inactive-description  {
  display: -webkit-box;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: config.$brand-color-foreground-primary;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.inactive-text, .active-text {
  @include config.brand-text-default-regular;
  margin: 0 5% 0 0;
  height: 100%;
  overflow-y: scroll;
}

.active-description, .inactive-description {
  @include config.brand-subtext-default-regular;
  margin: 15px 5% 0 0;
}

.active-text, .active-description {
  font-weight: config.$brand-fw-semibold;
  height: 100%;
  overflow-y: scroll;
}

.completed-icon-style {
  cursor: pointer;
  min-width: 20px;
}

.unchecked-icon-style {
  margin-top: 2px;
}

// IPad and above
@media screen and (min-width: config.$breakpoint-md) {
  .large-list-item-style {
    height: 125px;
    overflow-y: scroll;
  }

  .small-list-item-style {
    height: 80px;
    overflow-y: scroll;
  }

  .inactive-container,
  .active-container {
    margin-right: 20px;
  }
}

@media screen and (min-width: config.$breakpoint-xl) {
  .active-description, .inactive-description {
    -webkit-line-clamp: 3;
  }

  .inactive-text, .active-text {
    -webkit-line-clamp: 2;
  }
}
