.button-style {
  margin: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-field-style {
  min-width: 300px !important;
  margin-top: 10px !important;
}

.error-alert-style {
  margin: 20px auto 0 auto;
  max-width: 400px;
}
