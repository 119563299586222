@use 'sass:color';
@use 'src/styles/config';

.container {
  background: #fff;
  top: 0;
  right: 0;
  width: 100%;
  box-shadow: 0 1px 7px 0 rgba(99, 102, 106, 0.4);
  z-index: 101;
  padding: 21px 0 21px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 80px;
}

.logo {
  max-width: 275px;
  padding: 6px 0 6px 0;
  max-height: 65px;
}

.logo-button {
  background: transparent;
  border: none;
  cursor: pointer;
  min-height: 70px;
}

.container a img {
  width: 52px;
  margin-right: 8px;
}

.menu-icon-style {
  width: 40px;
  cursor: pointer;
  border: unset;
  background-color: unset;
}

.menu-icon-style img {
  width: 100%;
}

.sect-in {
  padding: 0 8px 0 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.wrapper {
  width: 100%;
}

.logout-container {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .container {
    text-align: center;
  }

  .text {
    left: 0;
    right: 0;
  }

  .logo {
    max-width: 250px;
  }

  .sect-in {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .sect-in {
    padding: 0 2%;
  }

  :global(.modal-open) .container {
    padding-right: 17px;
  }
}

@media (min-width: 768px) {
  .menu-icon-style {
    display: none;
  }

  .logout-container {
    margin-right: 0;
  }
}

.nav-list {
  width: 100%;
  padding: 10px 20px;
  text-align: right;
  position: relative;
}

.nav-title span {
  @include config.brand-header-sm;
  display: inline-block;
  cursor: pointer;
  position: relative;
  outline: inherit;
  background-color: #fff !important;
  white-space: nowrap;
  text-transform: none;
}

.nav-title.active span {
  color: config.$brand-nav-bar-active-hover-color !important;
}

button.nav-title,
button.emergency-button-container {
  background-color: unset !important;
}

button.nav-title {
  padding: 0;
  margin: 6px 8px;
  min-width: min-content;
  height: 3px;
}

.nav-title.active {
  border-radius: 2px;
  box-shadow: 0 36px 0 0 config.$brand-nav-bar-active-hover-color;
  padding-top: 0;
  padding-bottom: 0;
}

.nav-title:hover span {
  color: config.$brand-nav-bar-active-hover-color !important;
}

@media screen and (max-width: 1250px) {
  .nav-title {
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 1080px) {
  .nav-title {
    margin-left: 10px;
  }
}

.logo-section {
  display: flex;
}

.buttons-section {
  display: flex;
  margin-left: 32px;
  padding: 24px 0 18px;
  column-gap: min(2vw, 60px);
}

.emergency-button-style {
  @include config.brand-text-lg-semibold;
  height: max-content;
  min-height: 1.75rem;
  border-radius: 8px;
  background-color: rgba(224, 32, 32, 0.04) !important;
  text-align: center;
  color: config.$brand-color-alert;
  text-transform: none;
  padding: 3px 5px 0;
}

.emergency-icon-style {
  padding-right: 4px;
}

@media only screen and (max-width: 767px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    max-height: max-content;
  }

  .emergency-button-container {
    padding: 0 0 5px 0 !important;
  }

  .logout-container {
    margin: auto;
  }

  .sect-in {
    flex-flow: column wrap;
    width: 100%;
  }

  .buttons-section {
    display: none !important;
  }

  .logo-section {
    justify-content: space-around;
    width: 100%;
  }

  .logo-button {
    width: 100%;
  }

  .logo {
    margin: auto;
  }

  .nav-list {
    padding: 10px 10px 0 10px;
  }
}

@media screen and (max-width: 991px) {
  .buttons-section {
    margin-left: 5px;
  }
}
