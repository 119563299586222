@use "sass:color";
@use "src/styles/config";

.text-style {
  @include config.brand-text-lg-regular;
  padding: 0.5rem 0.7rem;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-line;
  color: config.$brand-color-foreground-primary;
}

.user-message-style {
  color: #fff;
}
