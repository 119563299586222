@use "src/styles/config";

.img {
  width: 100%;
}

.container {
  margin-bottom: 50px;
}

.quote-container {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 200px;
  max-width: 400px;
}

.quote {
  @include config.brand-header-default;
  position: relative;
  padding: 15px;
  margin: 1em 0 1em 1em;
  border: 1px solid #b7bfc2;
  color: #454449;
  background: #fff;
  border-radius: 10px;
}

.quote:before {
  content: '';
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
  top: 33px;
  bottom: auto;
  left: -28px;
  border-width: 1px 28px 16px 0;
  border-color: transparent #b7bfc2;
}

.quote:after {
  content: '';
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
  top: 34px;
  bottom: auto;
  left: -24px;
  border-width: 1px 24px 13px 0;
  border-color: transparent #fff;
}

.quote span {
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: config.$brand-color-foreground-primary;
}

@media screen and (max-width: 991px) {
  .img {
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .quote-container {
    min-height: initial;
  }
}
