@use "sass:color";
@use "src/styles/config";

.container {
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(113, 109, 109, 0.3);
  border-radius: 5px;
  margin: 15px 0;
  text-align: center;
  width: 50%;
  align-self: flex-start;
  background: #fff;
}

.container img {
  min-height: 175px;
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.container h5 {
  @include config.brand-header-sm;
  width: 100%;
  border-radius: 5px;
  margin: 10px 0 5px 0;
}

.container p {
  @include config.brand-subtext-default-regular;
  margin: 0;
}

.text-outer {
  padding: 0 14px 14px 14px;
  text-align: left;
}

.view-button,
.reset-button {
  @include config.brand-text-sm-semibold;
  width: 100%;
  border: none;
  text-align: center;
  color: config.$brand-color-secondary;
  padding: 8px;
  display: block;
  border-top: 2px solid #f4f4f4;
  background: #fff;
}

.view-button:hover,
.reset-button:hover {
  color: config.$brand-color-primary;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .container {
    min-height: 150px;
    width: 78%;
  }
}
