@use "sass:color";
@use "src/styles/config";

.container {
    position: relative;
    height: 100%;
    touch-action: none;
    padding-bottom: env(safe-area-inset-bottom);
}

.video-container {
    position: relative;
    height: calc(100vh - 155px);
    overflow: hidden;
    padding-bottom: 0;
}

.footer-container {
    position: absolute;
    bottom: env(safe-area-inset-bottom, 0px);
    display: flex;
    z-index: 100;
    border-top: solid 1px color.change(config.$brand-color-foreground-secondary, $alpha: 0.15);
    width: 100vw;
    height: 75px;
    background-color: #fff;
    align-items: center;
}

.back-button {
    position: relative;
    width: 9.5em;
    left: 5%;
}

.spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 75vh;
}

.spinner-text-style {
    margin-left: 1em;
}

@media screen and (max-width: config.$breakpoint-md) {
    .back-button {
        width: 8em;
    }
}

@media screen and (max-width: config.$breakpoint-xl) {
    .video-container {
      position: relative;
      top: 0;
      height: 100%;
      bottom: 75px;
    }
}
