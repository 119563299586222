@use "sass:color";
@use "src/styles/config";

.top-section {
  padding: 54px 6% 20px 6%;
  margin: 0 auto;
  background: config.$brand-color-background-primary-light;
  text-align: center;
}

.top-section img {
  width: 65px;
}

.top-section h2 {
  @include config.brand-header-lg;
  text-align: center;
  margin: 28px 0 23px;
}

.top-section p {
  @include config.brand-subtext-default-regular;
  text-align: center;
}

.btm-section {
  text-align: center;
  margin: 35px 0;
}

.request-button {
  margin: auto;
}
