@use "sass:color";
@use "src/styles/config";

.top-section {
  padding: 54px 6% 20px 6%;
  margin: 0 auto;
  background: config.$brand-color-background-primary-light;
  text-align: center;
}

.top-section img {
  width: 65px;
}

.top-section h2 {
  @include config.brand-header-lg;
  margin: 0;
  margin-bottom: 23px;
  text-align: center;
  margin-top: 28px;
}

.top-section p {
  @include config.brand-subtext-default-regular;
  font-size: config.$brand-fs-subtext-default !important;
  text-align: center;
}

.btm-section {
  text-align: center;
  margin: 35px 0;
  @include config.brand-text-sm-regular;
}

.request-button {
  margin: auto;
}


@media screen and (max-width: 767px) {
  .top-section p {
    font-size: config.$brand-fs-subtext-default !important;
    text-align: center;
  }
}

