.input {
  width: 100px;
  text-align: center;
}

.button-style {
  margin: 20px auto 0;
}

.field-label-style {
  display: block;
}
