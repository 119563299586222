@use "sass:color";
@use "src/styles/config";

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: inherit;
  border-right: 1px solid #eee;
  position: relative;
}

.menu-container {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #eee;
}

.hamburger-button-style {
  width: 59px;
  background: #fff;
  height: 59px;
  background: none;
  border: 0;
  cursor: pointer;
}

.no-user-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  background-color: config.$brand-color-background-primary-lighter;
}

.no-user-found-text-style {
  @include config.brand-subtext-default-regular;
}

.chat-search {
  flex: 1;
  padding: 15px 10px 15px 35px;
  background: #f7f7f7 url(../../../../../assets/icons/search.svg) no-repeat 10px
    19px;
  background-size: 17px;
}

.chat-search input {
  @include config.brand-text-sm-regular;
  width: 100%;
  border: 0;
  outline: none;
  background: #f7f7f7;
}

.client-sec {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 2px solid #eee;
}

.client-sec h5 {
  @include config.brand-subtext-default-regular;
  text-transform: uppercase;
  margin: 0;
}

.client-sec .ftr-icon,
.client-sec .sort-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: none;
}

.client-sec .ftr-icon:hover {
  background: #f7f7f7;
}

.client-sec .sort-icon:hover {
  background: #f7f7f7;
}

.filter-sec {
  float: right;
  position: absolute;
  right: 15px;
}

.filter-sec img {
  width: auto;
  max-height: 15px;
  opacity: 0.5;
  cursor: pointer;
}

.filter-sec span {
  display: inline-block;
}

.list-sec {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
  display: flex;
  flex-direction: column;
}

/*.list-sec li {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: 1rem;*/
/*  text-transform: capitalize;*/
/*  width: 100%;*/
/*  position: relative;*/
/*  height: 65px;*/
/*}*/

/*.list-sec li:hover::before {*/
/*  content: '';*/
/*  width: 4px;*/
/*  border-radius: 3px;*/
/*  height: 80%;*/
/*  background: #50c8d8;*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*}*/

.sort-icon {
  float: right;
}

/* Base for label styling */
[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
}

[type='checkbox']:not(:checked) + label,
[type='checkbox']:checked + label {
  @include config.brand-subtext-default-semibold;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

/* checkbox aspect */
[type='checkbox']:not(:checked) + label:before,
[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
}

/* checked mark aspect */
[type='checkbox']:not(:checked) + label:after,
[type='checkbox']:checked + label:after {
  content: '\2713\0020';
  @include config.brand-text-default-regular;
  position: absolute;
  top: 4px;
  left: 5px;
  line-height: 12px;
  color: #fff;
  transition: all 0.2s;
}

/* checked mark aspect changes */
[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type='checkbox']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

[type='checkbox']:checked + label:before {
  border: 2px solid config.$brand-color-primary;
  background: config.$brand-color-primary;
}

/* tooltip */

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  @include config.brand-subtext-default-regular;
  position: absolute;
  bottom: -44px;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -38px;
  padding: 7px;
  width: 70px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-bottom: 5px solid rgba(0, 0, 0, 0.57);
  border-bottom: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.clients-list-end {
  @include config.brand-subtext-default-regular;
  text-align: center;
  padding: 10px;
  color: #756f6f;
}

.active-filters-count {
  @include config.brand-subtext-default-regular;
  background: #df342f;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 18px;
  display: inline-block;
  text-align: center;
  color: #fff;
  position: absolute;
  bottom: 24px;
  right: -4px;
  z-index: 100;
}
