.container {
  display: flex;
  flex-direction: row;
}

.media-card-container {
  width: 180px;
  min-width: 0;
  margin: 6px 6px 0 6px;
  border-radius: 5px;
}
