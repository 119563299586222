.container {
  margin-top: 20px;
}

.content {
  background: url('../../assets/img/layout/clouds.png') no-repeat;
  background-size: contain;
  background-position-y: 20px;
  background-origin: content-box;
}

@media screen and (max-width: 991px) {
  .content {
    background-position-y: 25px;
  }
}
