@use "src/styles/config";

.container {
  @include config.brand-text-lg-regular;
  display: inline-block;
  max-width: 30em;
  margin-bottom: 0.1em;
  background: rgb(255, 255, 255);
  letter-spacing: 0;
  padding: 6px 10px;
  border-radius: 0 14px 14px 14px;
  border: 0.5px solid rgba(99,102,106,0.15);
  background-color: #FFFFFF;
  box-shadow: 0 1px 0 0 rgba(75,75,80,0.15)
}

.user-message {
  float: right;
  background-color: config.$brand-color-primary;
  color: #fff;
  border-radius: 14px 0 14px 14px;
  padding: 5px 20px 5px 10px;
  max-width: 100%;
}
.user-message span {
  @include config.brand-text-lg-semibold;
  color: #fff;
  font-weight: config.$brand-fw-semibold;
}
