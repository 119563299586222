@use "sass:color";
@use "src/styles/config";

.text-container {
  @include config.brand-subtext-default-semibold;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  margin-top: 15px;
}

.img-style {
    height: 165px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .card-container {
    min-height: 375px;
    margin-top: 10px;
  }