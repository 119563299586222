@use "src/styles/config";

.container {
  display: flex;
  align-items: center;
}

.indicator {
  text-align: left;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.goal-image {
  margin-left: 10px;
  display: inline-block;
}

.category-text {
  @include config.brand-subtext-default-semibold;
  font-size: config.$brand-fs-subtext-default !important;
  margin-left: 10px;
}

@media screen and (max-width: 991px) {
  .category-text {
    width: 183px;
    display: inline-block;
  }
}
