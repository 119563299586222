@use "src/styles/config";

.question {
  @include config.brand-text-lg-semibold;
  text-align: center;
  width: 80%;
  margin: 20px auto 0 auto;
}

.input-container {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .question {
    width: 100%;
  }
}
