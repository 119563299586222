@use "src/styles/config";

.container {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
}

.down-arrow-style {
  width: 20px;
  float: right;
  cursor: pointer;
}

.info-item-style {
  @include config.brand-subtext-default-regular;
  width: 100%;
  float: left;
  color: #64666a;
  margin: 4px 0;
}

.info-item-style span {
  width: 120px;
  float: left;
  color: #000;
}

.suicidal-alert-container {
  text-align: center;
  margin: 0 auto;
}

.suicidal-alert-button-style {
  text-transform: uppercase;
  margin-top: 20px;
  cursor: pointer;
  width: fit-content;
}

.info-container {
  width: 100%;
}

.toggle-button-style {
  text-align: left;
  padding: 0;
  display: flex;
}
