@use "sass:color";
@use "src/styles/config";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3px;
  z-index: 0;
}

.input-style {
  @include config.brand-text-default-regular;
  width: 100%;
  outline: none;
  max-height: 100px;
  border: 0;
  resize: none;
  touch-action: none;
}

.input-style::placeholder {
  color: color.change(config.$brand-color-foreground-primary, $alpha: 0.4);
  font-weight: config.$brand-fw-semibold;
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 45rem;
  padding: 16px 12px 13px 24px;
  border-radius: 50px;
  margin-bottom: 5px;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
}

.send-button-style {
  cursor: pointer;
  background: none;
  border: 0;
  position: relative;
  height: 30px;
}

.send-button-style img {
  height: 100%;
}

// for touch screen devices like mobile, tablets, etc
.touch-screen-style {
  width: 98%;
  margin: 2px auto;
}

.desktop-style {
  background-color: config.$brand-color-background-primary-lighter;
}
.browser-tab-style {
  bottom: 0;
}

.tab-style {
  bottom: calc(env(safe-area-inset-bottom) + 3.4rem);
}

.tab-with-keyboard-style {
  bottom: 0;
}

@media screen and (max-width: config.$breakpoint-xl) {
  .input-container {
    width: 98%;
    margin: 3px auto;
  }
}
