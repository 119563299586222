@use "src/styles/config";
.slider-style {
    width: 100%;
    height: 5px;
    background: whitesmoke !important;
    transition: width 0.1s;
    cursor: pointer;
    z-index: 10;
}

.slider-bar-style {
    position: absolute;
    background: config.$brand-color-secondary !important;
    top: 0;
    bottom: 0;
    left: 0;
}

.rounded-style {
    border-radius: 4px;
}

.slider-handle-style {
    position: absolute;
    width: 16px !important;
    height: 16px;
    background: config.$brand-color-secondary !important;
    border-radius: 50%;
    transform: scale(1);
    transition: transform 0.2s;
    top: 0;
    margin-top: -5px;
    margin-left: -5px;
}

.slider-hover-style {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s linear;
}

.slider-style:hover .slider-hover-style{
    visibility: visible;
    opacity: 1;
}

.slider-handle-style:hover {
    transform: scale(1.3);
}