@use "sass:color";
@use "src/styles/config";

.container-style {
  position: relative;
  margin: 0% 10%;
}

.selected-goal-container {
  margin-top: 20px;
  padding: 10px 20px;
}

.retake-survey-title-style {
  @include config.brand-header-sm;
  font-weight: normal;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .selected-goal-container {
    padding: 0;
  }

  .retake-survey-title-style {
    padding-left: 0;
  }

  .container-style {
    margin: 0;
  }
}
