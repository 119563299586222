@use "src/styles/config";

.arrows {
  height: 47px;
  width: 100%;
  position: absolute;
  top: 45vh;
  padding: 0 2%;
}
.prev-button {
  background: transparent;
  border: 0;
  cursor: pointer;
}
.next-button {
  background: transparent;
  float: right;
  border: 0;
  cursor: pointer;
}

.bullets {
  position: absolute;
  bottom: 2em;
  text-align: right;
  width: 100%;
  padding: 0 13%;
}

.swiper_bullets {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: wheat;
  display: inline-block;
  margin: 3px;
}

.active_swiper_bullet {
  height: 10px;
  width: 20px;
  border-radius: 32px;
  background: white;
  display: inline-block;
  margin: 3px;
}

.swiper_bullets_screen6 {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #f7a599;
  display: inline-block;
  margin: 3px;
}

.active_swiper_bullet_screen6 {
  height: 10px;
  width: 20px;
  border-radius: 32px;
  background: config.$brand-color-secondary;
  display: inline-block;
  margin: 3px;
}

.swiper_bullets_screen9 {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #80cfda;
  display: inline-block;
  margin: 3px;
}

.active_swiper_bullet_screen9 {
  height: 10px;
  width: 20px;
  border-radius: 32px;
  background: config.$brand-color-primary;
  display: inline-block;
  margin: 3px;
}

@media screen and (max-width: 991px) {
  .arrows {
    display: none;
  }

  .bullets {
    bottom: 1.5em;
    padding: 0 3em;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .bullets {
    bottom: 1.5em;
    padding: 0 3em;
  }
}

button:focus {
  outline: 0;
}

.swipable-views-container {
  overflow: hidden;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .arrows {
    display: none;
  }
  .bullets {
    bottom: 1.5em;
    padding: 0 7em;
  }
}
