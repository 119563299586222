@use "sass:color";
@use "src/styles/config";

.top-section {
  padding: 48px 20%;
  margin: 0 auto;
  background: config.$brand-color-background-primary-light;
  position: relative;
}

.top-section h2 {
  @include config.brand-header-xl; 
  margin: 0;
  margin-bottom: 27px;
  text-align: center;
}

.top-section p {
  @include config.brand-text-lg-regular;
  text-align: center;
}

.btm-section {
  @include config.brand-text-sm-regular;
  text-align: center;
  margin: 76px 0 38px 0;
}

.request-button {
  margin: auto;
}

.mid-img {
  position: absolute;
  bottom: -51px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.mid-img img {
  width: 320px;
}

.error-msg {
  @include config.brand-subtext-default-regular;
  width: 70%;
  margin: 10px auto 0 auto;
}
