@use "sass:color";
@use "src/styles/config";

.tracker-card-title-style {
  @include config.brand-header-sm;
  font-weight: normal;
  padding-left: 4px;
  padding-bottom: 8px;
}

.graph-container {
  height: 336px;
}
