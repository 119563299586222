@use "sass:color";
@use "src/styles/config";

.content-container {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.content-container img {
  height: 50px;
}

.details-container {
  display: flex;
  align-items: center;
}

.details-container h4 {
  @include config.brand-header-default;
  margin: 0;
}

.details-container p {
  @include config.brand-text-default-regular;
  margin-bottom: 0.5em;
}

.details-container img {
  width: 36px;
  height: 36px;
}

.goal-name-text-style {
  @include config.brand-header-sm;
  line-height: 1.2rem;
  margin-left: 12px;
  max-width: calc(100% - 170px);
  min-width: 140px;
}

.description-text-style {
  margin: 5px 0;
  max-width: 400px;
}
