@use "src/styles/config";

.in-progress-text-display-style {
  padding-top: 11px;
  padding-right: inherit;
  display: flex;


}
.in-progress-icon-style {
  color: config.$color-orange;
  height: 100%;
}
.in-progress-title-style {
  @include config.brand-text-default-regular;
  padding: 0 10px;
}
.in-progress-icon-container-style {
    float: left;
}