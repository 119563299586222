.left_sidebar {
  float: left;
  width: 30%;
  min-width: 300px;
  background: #007bff;
}

.main {
  box-sizing: border-box;
  margin: 0 5%;
}

:global(.modal-open) {
  overflow: hidden;
}

.full-page-container {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

@media (max-width: 767px) {
  .full-page-container {
    width: 100vw;
  }

  .main {
    width: 100%;
    margin: 0px;
  }
}

@media (min-width: 769px) {
  .full-page-container {
    width: calc(100vw - 272px);
  }
  :global(.modal-open) {
    padding-right: 17px;
  }
}
