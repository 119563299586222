@use "src/styles/config";

.container {
  position: relative;
  padding: 2rem 4rem;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.top-panel-style {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid lightgray;
}

.top-panel-style  h3 {
  @include config.brand-header-xl;
}

.content-style {
  padding: 1rem 0;
  text-align: justify;
}

.heading-style {
  @include config.brand-header-lg;
}

.duration-style {
  @include config.brand-text-default-regular;
  margin-bottom: 15px;
}

.toggle-to-video-button-style {
  display: inline-flex;
  padding: 7px 25px;
  border-radius: 20px;
  border: solid 1px config.$brand-color-foreground-primary;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: config.$brand-fw-bold;
  z-index: 100;
  width: max-content;
}

.toggle-to-video-button-style img {
  margin-top: 2px;
}

.toggle-button-text-style {
  margin-left: 8px;
}

.subheading-style {
  @include config.brand-header-default;
}

@media only screen and (max-width: 991px) {
  .heading-style {
    @include config.brand-header-sm;
  }
}


@media only screen and (max-width: config.$breakpoint-md) {
  .content-style {
    padding-bottom: 4rem;
   }

  .top-panel-style {
    flex-direction: column;
  }

  .duration-style {
    @include config.brand-subtext-default-regular;
  }

  .toggle-to-video-button-style {
    padding: 5px 20px;
    margin-bottom: 8px;
  }

  .heading-style {
    @include config.brand-header-lg;
  }
}
