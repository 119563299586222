@use "src/styles/config";

.text-input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1vh 5vw 1vh 1vw;
  overflow: auto;
  height: inherit;
  margin-left: 5vw;
  color: config.$brand-color-foreground-primary;
}

.text-input-header-style {
  letter-spacing: 0.2px;
  margin: 10px 0px;
  @include config.brand-header-default;
  color: config.$brand-color-foreground-primary;
}

.text-input-meta-container {
  padding-right: 12px;
}

.text-input-content-style {
  letter-spacing: -0.19px;
}

.text-input-title-style {
  @include config.brand-text-default-regular;
}

.text-input-sub-title-style {
  @include config.brand-subtext-default-regular;
  padding-top: 5px;
}

.text-input-additional-info-style {
  @include config.brand-subtext-default-regular;
  padding-top: 5px;
}

.custom-input-container {
  margin-top: 10px;
}

@media screen and (min-width: config.$breakpoint-md) {
  .text-input-header-style {
    @include config.brand-header-lg;
  }
}

@media screen and (min-width: config.$breakpoint-lg) {
  .text-input-container {
    padding-right: 30vw;
    padding-left: 8vw;
  }

  .text-input-meta-container {
    padding-right: 150px;
    text-align: justify;
  }

  .separator-style {
    padding: 10px 20px 10px 0;
  }

  .text-input-header-style {
    margin-top: 20px;
  }
}
