@use "src/styles/config";

.header-container {
    text-align: center;
    margin: 0;
    background: config.$brand-color-background-primary-lighter;
    padding: 20px;
  }
  
.header-container h2 {
    @include config.brand-header-lg;
}

.card-container {
    padding: 10px 40px 10px 40px;
    max-width: 700px;
}

.footer-button-style {
    margin-right: 10px;
}

.card-container .icon-style {
    @include config.brand-text-default-regular;
    color: inherit;
    margin-right: 5px;
}

.button-content-style {
    display: flex;
    align-items: center;
}

.button-text-style {
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: config.$breakpoint-md) {
    .card-container {
        padding: 5px;
    }
    .footer-button-style {
        font-size: config.$brand-fs-subtext-default;
        line-height: 0.875rem;
        padding: 10px 17px;
    }
}

@media only screen and (min-width: config.$breakpoint-md) {
    .header-container h2 {
        line-height: 1.25rem;
    }
}