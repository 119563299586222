@use "src/styles/config";

.container {
  @include config.brand-subtext-default-regular;
  display: flex;
  align-items: center;
  margin-top: 2px;
  color: #4bb543;
}

.container svg {
  font-size: 1.3rem;
}

.message-style {
  margin-right: 5px;
}
