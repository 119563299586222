@use "src/styles/config";

.question-margin {
  margin-top: 50px;
}

.interstitial-margin {
  margin-top: 25px;
}

.questions-container {
  margin-top: 80px;
}

.questions-container label span {
  @include config.brand-text-default-semibold;
  color: inherit;
}

.progress-bar {
  margin-top: 5px;
}

.spinner-container {
  text-align: center;
  padding: 50px 0 50px 0;
}

.submit-button-container {
  margin-top: 50px;
  padding: 20px 50px;
  border-top: 2px solid rgba(211, 211, 211, 0.3);
}

.assessment-footer-interstitial {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.assessment-footer-interstitial-elevated {
  bottom: calc(env(safe-area-inset-bottom) + 57px);
  display: flex;
  justify-content: space-around;
}

.next-button-container {
  text-align: right;
}

.interstitial-header {
  padding-top: 5%;
}

@media screen and (max-width: 991px) {
  .question-margin,
  .interstitial-margin {
    margin-top: 15px;
  }

  .questions-container {
    margin-top: 25px;
  }
}

.aligned-button-style:active,
.aligned-button-style:focus,
.aligned-button-style:hover {
  color: config.$brand-color-secondary;
  text-decoration: underline;
}

.confidentiality-badge-wrapper {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  padding: 0 20px;
}
