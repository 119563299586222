@use "sass:color";
@use "src/styles/config";

.coach-request-container {
  position: relative;
  background: #ffffff;
  margin: 20px auto;
  text-align: center;
  border-radius: 9px;
  width: 450px;
}

.coach-request-modal-container {
  position: relative;
  background: #ffffff;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 9px;
  width: 100%;
}

.coach-request-top-panel {
  background-color: config.$brand-color-background-primary-light;
  padding: 20px;
  border-radius: 9px;
  margin: auto;
  width: 100%;
}

.confirmation-icon {
  text-align: center;
}

.confirmation-icon img {
  height: 42px;
  object-fit: contain;
}

.check-circle-icon {
  color: #4dcada;
  font-size: 3.125rem !important;
}

.coach-request-text {
  @include config.brand-header-default;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: config.$brand-color-foreground-primary;
  padding: 10px 40px;
}

.coach-icon {
  margin-top: -45px;
  height: 100px;
}

.coach-requested {
  @include config.brand-text-default-regular;
  padding: 10px 75px 0;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .coach-request-text {
    padding: 10px;
  }

  .coach-request-container {
    width: 350px;
  }

  .coach-request-modal-container {
    width: 100%;
  }
}
