@use "src/styles/config";

.history-empty-container {
  width: 100%;
  height: 100px;
  background-color: config.$color-grey-light-medium;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 86px;
  white-space: pre;
  text-align: center;
  border-radius: 8px;
}

.text-input-header-style {
  @include config.brand-header-default;
  margin-top: 8px;
  margin-bottom: 18px;
  color: config.$brand-color-foreground-primary;
}

@media screen and (min-width: config.$breakpoint-md) {
  .text-input-log-style {
    padding-right: 22px;
  }
  .text-input-header-style {
    @include config.brand-header-lg;
  }
}
