@use 'sass:color';
@use 'src/styles/config';

.title {
  @include config.brand-text-default-regular;
  width: 220px;
  background: #b3b7b9;
  color: #fff;
  padding: 3px 0;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 20px 0;
  line-height: 19px;
}

.container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 12px;
}

.container h3 {
  @include config.brand-text-default-regular;
}

.other-options-container > div:nth-child(3) {
  margin-top: 20px;
}

.focus-card-container,
.other-options-container,
.recommended-option-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.focus-card-container {
  height: calc(100% + 6px);
}

.other-options-container {
  padding-left: 8px;
}

.focus-area-label-style,
.recommended-label-style,
.other-option-label-style {
  @include config.brand-subtext-default-regular;
  text-transform: uppercase;
  padding: 2px 13px;
  border-radius: 3px;
  width: fit-content;
}

.focus-area-label-style {
  color: config.$brand-color-foreground-primary;
  border: 1px solid config.$brand-color-background-highlight;
  background-color: config.$brand-color-background-highlight;
}

.recommended-label-style {
  color: config.$brand-color-foreground-primary;
  border: 1px solid config.$brand-color-background-neutral;
  background-color: config.$brand-color-background-neutral;
}

.other-option-label-style {
  color: config.$brand-color-foreground-primary;
  border: 1px solid #dddddd;
  background-color: #fff;
}

@media screen and (max-width: config.$breakpoint-lg) {
  .container {
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    row-gap: 20px;
  }

  .other-options-container {
    padding-left: 0;
  }
}
