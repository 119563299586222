.container-style {
  position: relative;
  margin: 0% 10%;
}

@media screen and (max-width: 767px) {
  .container-style {
    margin: 0;
  }
}
