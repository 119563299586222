@use "sass:color";
@use "src/styles/config";

.container {
    width: 100%;
    height: 9.8rem;
    margin: 0.8em;
    border-radius: 8px;
    margin: 10px 20px 10px 4px;
    box-shadow: 0 3px 4px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.14), 0 0 6px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.1);
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}
.icon-style {
    padding: 10px 10px 0 10px;
    display: flex;
    justify-content: space-between;
}
.label-style {
    padding: 0 10px 0px 10px;
    display: flex;
    justify-content: space-between;
}

.slider-container {
    padding: 10px 30px 0 30px;
}

.emoticon-style {
    height: 50px;
}

@media only screen and (max-width: 767px) {
    .container {
        width: 98%;
    }
}
