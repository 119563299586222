.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.top-controls {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    filter: brightness(0.8);
    margin-bottom: 8px;
    z-index: 10;
}

.player-button {
    background: transparent;
    border: none;
    cursor: pointer;
    margin: -2px 3px 0 0;
}

.top-left-controls {
    display: flex;
    justify-content: center;
}

.top-right-controls {
    display: flex;
    justify-content: flex-end;
    width: 13%;
}

.volume-controls-style {
    display: flex;
}

.volume-slider-style {
    width: 100px;
    display: flex;
    align-items: center;
    padding-bottom: 9px;
    margin-right: 12px;
    transition: margin .2s cubic-bezier(0.4,0,1,1),width .2s cubic-bezier(0.4,0,1,1);
}

.timer {
    margin-left: 4px;
}

.timer, .white-icons{
    color: white;
}

.hidden-controls-style {
    visibility: hidden;
}

.hide-volume-slider-style {
    visibility: hidden;
    width: 0;
    margin: 0;
}