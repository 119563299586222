@use "sass:color";
@use "src/styles/config";

.container {
  border-radius: 8px;
  box-shadow: 0 3px 4px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.14),
    0 0 6px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.1);
  margin: 18px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: config.$color-grey-lightest;
  border-radius: 8px 8px 0 0;
  height: 30px;
  padding: 0 14px;
}

.date-style {
  @include config.brand-subtext-default-semibold;
  color: config.$color-blue;
}

.expand-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.text-style {
  @include config.brand-text-default-regular;
  padding: 8px 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: config.$brand-color-foreground-primary;
}

.text-expanded-style {
  white-space: pre-wrap;
}
