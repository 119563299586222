@use "sass:color";
@use "src/styles/config";

.container {
    width: 100%;
    margin: 10px 0 30px 0;
}

.card-style {
    padding: 10px 20px 0 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 3px 4px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.14), 0 0 6px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.1);
}

.text-style {
    @include config.brand-text-default-semibold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    overflow: hidden;
    max-width: 80%;
}

.slider-container {
    margin: 0 20px 10px 20px;
}

// IPad and above
@media screen and (min-width: config.$breakpoint-md) {
    .card-style {
        padding: 25px 20px 20px 20px;
    }

    .text-style {
        max-width: 75%;
        position: relative;
        top: 5px;
    }
}
