@use "src/styles/config";

.tab-container {
  margin: 0 3px 0 3px;
}

.tab-style img {
  width: 30px;
}

.tab-style {
  display: flex;
  justify-content: center;
}

.tab-text-style {
  @include config.brand-subtext-default-regular;
  position: relative;
  top: 6px;
  padding-left: 8px;
}

@media screen and (max-width: config.$breakpoint-md) {
  .tab-style img {
    width: 25px;
  }

  .tab-text-style {
    @include config.brand-subtext-default-regular;
  }
}
