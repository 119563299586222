.container {
  width: 45rem;
  margin: auto;
  height: inherit;
  overflow-y: auto;
  background: #fff;
  padding-bottom: env(safe-area-inset-bottom);
}

.messages-container {
  color: white;
  display: flex;
  flex: 10;
  background: #fff;
  flex-direction: column;
  padding: 0.9rem 0 0.5rem 0;
}

.message-style {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3px 12px 0 12px;
}

@media screen and (max-width: 767px) {
  .container {
    bottom: 0;
    right: 0;
    width: 100%;
  }
}
