@use "src/styles/config";

.container {
    position: relative;
    margin: 5% auto;
    background: #fff;
    max-width: 350px;
    border-radius: 6px;
    padding: 30px 20px;
}

.error-text-style {
    @include config.brand-subtext-default-regular;
    color: config.$brand-color-alert;
    margin: 0 30px 0 30px;
    text-align: center;
}

.back-button-style {
    position: absolute;
    left: 3%;
    top: 3%;
    cursor: pointer;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: #757575;
}

@media screen and (max-width: 991px) {
    .container {
      margin: 5% auto;
      background: #fff;
      max-width: 350px;
      border-radius: 6px;
      padding: 30px 20px;
    }

    .back-button-style {
        top: 2%
    }
}

.header-style {
    @include config.brand-header-lg;
    color: config.$brand-color-foreground-primary;
    margin-bottom: 20px;
    text-align: left;
    margin-top:40px;
}