@use "src/styles/config";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: inherit;
}

.content {
    text-align: center;
}

.header-style {
    @include config.brand-header-xl; 
    margin: 15px 0 5px 0;
}

.subheading-style {
    @include config.brand-text-lg-regular;
}
