@use "src/styles/config";

.container {
  margin-top: 15px;
  text-align: center;
}

.container h3 {
  @include config.brand-header-default;
  color: #4c4c4ecc;
}

.container button {
  margin: 15px auto 0;
}
