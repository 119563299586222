@use "src/styles/config";

.container {
  @include config.brand-text-default-semibold;
  border: none;
  text-align: left;
  color: config.$brand-color-secondary;
  text-decoration: underline;
  margin-top: 5px;
}

.container:hover {
  color: config.$brand-color-secondary;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .container {
    text-align: center;
  }

  .aligned-button {
    width: 100%;
  }
}
