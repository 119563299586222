// Brand Colors
$primary: #00abd2;
$secondary: #ff715b;

// * Color used for all font sizes except the smallest
$foreground-primary: #2a2a2b;

// * Color used for the smallest font size, shadows, icons, dividers, borders, and other elements
$foreground-secondary: #515962;

// * Color used for errors, alerts, emergency, and other important messages
$alert: #e10506;

// * Background colors
$background-primary-light: #e5f7f9;
$background-primary-lighter: #f5fcfd;
$background-neutral: #f7f7f7;
$background-highlight: #fff4d7;

:export {
  brandColorPrimary: $primary;
  brandColorForegroundPrimary: $foreground-primary;
  brandColorForegroundSecondary: $foreground-secondary;
}
