@use "src/styles/config";

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  z-index: 99;
}
.lds-ring.small {
  width: 25px;
  height: 25px;
}
.lds-ring.center {
  margin-left: 0 !important;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 8px;
  border: 2px solid config.$brand-color-foreground-secondary;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: config.$brand-color-foreground-secondary transparent transparent transparent;
}
.lds-ring.small div {
  width: 19px;
  height: 19px;
}

.lds-ring.white div {
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
