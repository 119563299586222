@use "src/styles/config";

.name-collapsed-style {
  @include config.brand-subtext-default-semibold;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #f7f7f7;
  text-align: center;
  display: inline-block;
  line-height: 40px;
  text-transform: uppercase;
  border: 1px solid #eee;
}

.name-collapsed-style .unread-count {
  position: absolute !important;
  top: 4% !important;
  left: auto !important;
  right: 6px;
}

li .unread-count {
  @include config.brand-subtext-default-regular;
  background: config.$brand-color-primary;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  color: #fff;
  position: relative;
  left: 6px;
  top: -8px;
}

.container {
  text-align: left;
  text-transform: capitalize;
  display: flex;
  border: 0;
  cursor: pointer;
  position: relative;
  width: 100%;
  background: #fff;
}

.container:hover {
  background: #f7f7f7;
}

.container-active {
  background: #f7f7f7;
}

.container-expanded-style:before,
.container-expanded-style:hover::before {
  content: '';
  width: 4px;
  position: absolute;
  border-radius: 3px;
  top: 5px;
  bottom: 5px;
  right: 0;
}

.container-expanded-style:hover::before {
  background: #eee;
}

.name-collapsed-container {
  padding: 10px 0;
}

.container-active:before {
  /* Important style because we don't want to show over color on active elements */
  background: config.$brand-color-primary !important;
}

.name-style {
  @include config.brand-subtext-default-semibold;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  padding: 18px 5px 18px 25px;
}

.list-item-style {
  width: 100%;
}

.list-item-collapsed-style {
  display: flex;
  justify-content: center;
  align-items: center;
}
