.container {
  position: relative;
  width: 80%;
  margin: auto;
}

@media screen and (max-width: 991px) {
  .container {
    width: 100%;
    margin: auto;
  }
}
