@use "src/styles/config";

.coach-card-container {
  width: 450px;
  margin: auto;
}

.coach-card-header {
  text-align: center;
  line-height: 37px;
}

.divider {
  margin: 0;
}

.coach-card-header span {
  @include config.brand-subtext-default-regular;
  text-align: center;
}

.coach-details-container {
  background-image: config.$brand-coach-details-background;
  display: flex;
  justify-content: flex-start;
  color: #fff;
  padding: 10px 9px 10px 17px;
}

.coach-image {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 #ee9f11;
  border: solid 4px rgb(253, 206, 103);
}

.coach-desig {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
}

.coach-name,
.coach-degree {
  color: #fff;
}

.coach-name {
  color: #fff;
  margin-bottom: 0.1rem;
  padding-right: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.coach-about {
  border-radius: 4px 4px 0 0;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.coach-bio {
  background-color: #f9f9f9;
  padding: 10px 9px 10px 17.2px;
  margin: 0;
  width: 100%;
}

.coach-message {
  background-color: #fff;
  padding: 10px 9px 10px 17.2px;
  margin: 0;
  width: 100%;
}

.coach-talk-button {
  @include config.brand-text-lg-regular;
  display: block;
  background-color: #fff;
  border: none;
  padding: 5px;
  outline: none;
  color: red;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .coach-card-container {
    width: 350px;
  }
}
