@use "src/styles/config";

.container {
  @include config.brand-text-default-semibold;
  display: flex;
  margin: auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 600px;
  padding: 0 16px;
  height: calc(100vh - 90px);
}

.icon-style {
  height: 64px;
  width: 64px;
  margin-bottom: 40px;
}

.button-style {
  margin-top: 48px;
  width: 256px;
}

@media screen and (max-width: 767px) {
  .container {
    height: calc(100vh - 115px);
  }
}
