@use "src/styles/config";

.container {
  width: fit-content;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 5px 12px 0px 0px;
}

.thumbnail {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 35px;
  text-align: center;
  border-radius: 10px;
}

.play-icon {
  position: relative;
  color: #ffffffde;
  top: 20%;
  color: rgba(0, 0, 0, 0.5);
}

.preview-title {
  @include config.brand-subtext-default-regular;
  color: #55565b;
  margin-left: 6px;
}

.remove-preview {
  margin: 5px 5px 0px 5px;
  cursor: pointer;
}

.remove-preview .clear-icon {
  font-size: 1.125rem;
}
