@use "src/styles/config";

.container {
  text-align: center;
}

.header-text {
  color: #4b4a50;
  width: 50%;
  margin: auto;
}

.start-button {
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;
  white-space: nowrap;
}

.skip-button {
  border: none;
  padding: 0;
  color: #5db06c;
  height: 30px;
  font-weight: config.$brand-fw-bold;
}

.button-container {
  margin-top: 20px;
}

@media screen and (max-width: 991px) {
  .header-text {
    width: 100%;
    margin-bottom: 25px;
  }
}
