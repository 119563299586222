@use "src/styles/config";

.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);
}

.content-container {
  width: min(400px, calc(100vw - 48px));
  margin: auto 0;
  padding: 50px 0 96px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  overflow-y: auto;
  height: max-content;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.content-container::-webkit-scrollbar {
  display: none;
}

.spinner-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 90px);
}

.spinner-container {
  position: relative;
  margin-bottom: 150px;
}

.retry-button-container {
  margin: 70px auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.spinner-text-style {
  @include config.brand-text-default-regular;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 20px;
}

.field-error-style {
  @include config.brand-subtext-default-regular;
  color: config.$brand-color-secondary;
}

@media screen and (max-width: 767px) {
  .spinner-flex-container {
    height: calc(100vh - 115px);
  }
}
