@use "sass:color";
@use "src/styles/config";

.container {
  @include config.brand-subtext-default-regular;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: config.$brand-color-background-primary-lighter;
}

.container span {
  background-color: config.$brand-color-background-primary-light;
  box-shadow: 0 1px 0.5px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.15);
  padding: 2px 15px;
  margin: 5px 0 15px 0;
  border-radius: 2px;
}
