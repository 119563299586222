@use 'sass:color';
@use 'src/styles/config';

.container {
  min-width: 265px;
  min-height: 162px;
  position: relative;
  margin: 0.8em 0;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(99, 102, 106, 0.1),
    0 3px 4px 0 rgba(99, 102, 106, 0.14) !important;
}

.top-panel-style {
  display: flex;
  min-height: 45px;
}

.rectangle-style {
  margin-top: 8px;
  border-radius: 0 200px 200px 0;
  border-right: 5px solid config.$brand-color-primary;
  height: 2rem;
}

.button-style {
  margin-top: 10px;
  position: absolute;
  bottom: 10px;
}

.header-container-style {
  @include config.brand-subtext-default-regular;
  color: config.$brand-color-foreground-secondary;
  overflow: hidden;
  display: flex;
  margin: 15px 10px 0 15px;
}

.header-style {
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 130px;
  max-width: 138px;
}

.sub-heading-style {
  @include config.brand-text-lg-semibold;
  line-height: 1.5rem;
  min-height: 50px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bottom-panel-style {
  margin: 5px 10px 10px 20px;
}

@media screen and (max-width: 767px) {
  .container {
    min-width: 180px;
    min-height: 12.5rem;
    flex-direction: column;
  }

  .header-container-style {
    flex-direction: column;
  }

  .header-style {
    padding-left: 0;
    padding-top: 10px;
    min-width: 120px;
    max-width: 120px;
  }

  .sub-heading-style {
    padding-top: 5px;
    min-height: 75px;
    -webkit-line-clamp: 3;
  }
}
