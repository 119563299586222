@use 'src/styles/config';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 6px 0 rgba(99, 102, 106, 0.1),
    0 3px 4px 0 rgba(99, 102, 106, 0.2) !important;
}

.content-container {
  display: flex;
  min-height: 200px;
  flex-grow: 1;
}

.content-container img {
  max-width: 7rem;
  object-fit: cover;
}

.content-container div {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.content-container h2 {
  @include config.brand-header-default;
}

.content-container p {
  @include config.brand-subtext-default-regular;
}

.footer-container {
  display: flex;
  justify-content: flex-start;
  padding: 10px 15px;
  border-top: 1px solid rgba(151, 151, 151, 0.4);
}

@media only screen and (max-width: config.$breakpoint-md) {
  .content-container {
    height: 100%;
  }

  .content-container p {
    @include config.brand-subtext-default-regular;
  }

  .content-container h2 {
    @include config.brand-header-sm;
  }
}
