@use "src/styles/config";

.top-container {
  display: flex;
  background: config.$brand-color-background-primary-light;
  height: 165px;
  color: rgb(40, 71, 88);
}

.image-style {
  width: 30%;
}

.goal-detail-text-style {
  @include config.brand-subtext-default-regular;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description-style {
  @include config.brand-text-default-regular;
}

.sub-header-style {
  @include config.brand-header-default;
  min-height: 60px;
  display: flex;
  align-items: center;
  padding-right: 12px;
}

.bottom-container {
  padding: 20px;
}

.start-text-style {
  @include config.brand-text-lg-regular;
  border-width: 2px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .goal-detail-text-style {
    @include config.brand-text-default-regular;
    padding: 0;
  }

  .top-container {
    height: 150px;
  }

  .bottom-container {
    padding: 12px;
  }

  .image-style {
    min-width: 102px;
  }
}
