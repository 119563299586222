@use "src/styles/config";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-text-style {
  @include config.brand-subtext-default-semibold;
}

.label-style {
  @include config.brand-subtext-default-regular;
  color: white;
  padding: 4px;
  margin-left: 8px;
  border-radius: 4px;
  height: 24px;
  width: 32px;
  display: inline-block;
  text-align: center;
}

.suicidal-bg-color {
  background-color: config.$brand-color-alert;
}

.domestic-violence-bg-color {
  background-color: #e78555;
}
