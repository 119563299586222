@use "src/styles/config";

.wrapper {
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    padding: 10px 0 12px 0;
    height: inherit;
}

.container {
    position: relative;
    padding: 0 3% 0 3%;
}

.subheader-style {
    @include config.brand-header-lg;
    position: relative;
}

.description-style {
    @include config.brand-text-lg-regular;
    position: relative;
    margin: 10px 0 20px 0;
}

.situation-list-container {
    margin: 0 3% 0 3%;
}

// Smaller devices
@media screen and (max-width: config.$breakpoint-md) {
    .subheader-style,
    .description-style {
        width: 100%;
    }
}

// IPad and above
@media screen and (min-width: config.$breakpoint-md) {
    .container {
        padding: 0 10% 0 10%;
    }

    .situation-list-container {
        margin: 0 10% 0 10%;
    }
    .wrapper {
        height: calc(100vh - 203px);
    }
}
