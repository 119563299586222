@use "src/styles/config";

.fieldset {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  padding-bottom: 20px;
  padding-left: 10px;
  margin-top: 25px;
  margin-bottom: 0;
}

.fieldset-legend {
  @include config.brand-subtext-default-regular;
  padding: 5px 0;
  width: auto;
  border: none;
  margin-bottom: 0;
}
