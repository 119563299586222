@use "src/styles/config";

.container {
  text-align: center;
  padding-top: 7px;
  padding-bottom: env(safe-area-inset-bottom);
}

.container li {
  @include config.brand-text-default-regular;
}

h3 {
  @include config.brand-header-lg;
  margin-bottom: 0;
}
