@use "sass:color";
@use "src/styles/config";

.popup-container {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10001;
  overflow-y: auto;
}

.popup-container .window {
  width: 90%;
  position: absolute;
  left: 50%;
  background: #fff;
  top: 50%;
  border-radius: 5px;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.close-btn {
  background: none;
  border: none;
  color: config.$brand-color-foreground-secondary;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 0.7rem;
  z-index: 10;
}

.close-btn i {
  font-size: 1.35rem;
  font-weight: config.$brand-fw-extrabold;
}

@media screen and (max-width: 991px) {
  .popup-container {
    inset: env(safe-area-inset-top) 0 env(safe-area-inset-bottom) 0 !important;
  }
  
  .popup-container .window {
    top: 10%;
    transform: translate(-50%, 0);
  }
}
