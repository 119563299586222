.container {
  display: flex;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(30, 140, 220);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  color: white;
}

.text-container {
  text-align: left;
  margin-left: 50px;
}

.icon-container-error {
  background: #d9534f;
}

.icon-container-warning {
  background: #f0ad4e;
}

.icon-container-success {
  background: #5cb85c;
}

.button-wrapper {
  background: none;
  border: none;
}
