@use 'sass:color';
@use 'src/styles/config';

.container {
  border-radius: 5px;
  border-top: 5px solid rgb(226, 55, 41);
}

.top-container {
  padding: 25px 30px 20px 30px;
  background: config.$brand-color-background-primary-lighter;
}

.top-container h2 {
  @include config.brand-header-lg;
  text-align: center;
  margin: 0;
}

.top-container p {
  text-align: center;
}

.bottom-container {
  padding-top: 20px;
}

.phone-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone-container span {
  margin-left: 10px;
}

.help-text-style {
  @include config.brand-text-lg-regular;
  margin-top: 10px;
}

.button-style {
  @include config.brand-text-lg-regular;
  color: #fff;
  margin: auto;
  padding: 10px 20px;
  margin-bottom: 30px;
}

.confirm-button-container {
  margin-top: 12px;
  text-align: center;
}

.confirm-button {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 20px;
}

.hr-style {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid
    color.change(config.$brand-color-foreground-secondary, $alpha: 0.2);
  margin: 0;
  padding: 0;
}

.footer-text-style {
  @include config.brand-text-lg-regular;
  text-align: center;
  margin-top: 12px;
}

.header-text-style {
  @include config.brand-text-lg-regular;
  margin: 12px 0 0 0;
}

.user-alert-text {
  @include config.brand-subtext-default-semibold;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .bottom-container {
    text-align: center;
  }

  .phone-container {
    width: 200px;
    margin: auto;
    background-color: red;
  }
}

@media screen and (min-width: 992px) {
  .button-style {
    background: none;
    color: config.$brand-color-secondary;
    margin: auto;
  }

  .button-style:hover {
    background: none;
  }
}
