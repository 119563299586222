@use "src/styles/config";

.panel-style {
  box-shadow: 3px 3px 15px 1px hsla(0, 4%, 89%, 0.7);
  border-radius: 5px;
  color: rgba(76, 76, 78, 0.5);
  background-color: #ffff;
  margin-top: 2%;
  min-width: 100%;
}

.container {
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.indicator-style {
  text-align: left;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.goal-image-style {
  margin-left: 10px;
  display: inline-block;
  height: 55px;
  width: 52.5px;
}

.goal-name-style {
  @include config.brand-subtext-default-semibold;
  margin-left: 10px;
}
