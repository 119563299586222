@use "src/styles/config";

.container {
  padding: 0;
  list-style: none;
  margin-top: 5px;
}

.container li i {
  margin-right: 5px;
}

.valid-rule-style {
  color: config.$brand-color-foreground-primary;
}
