@use "sass:color";
@use "src/styles/config";

.top-container {
  padding: 41px 12%;
  margin: 0 auto;
  height: 60%;
  background: config.$brand-color-background-primary-lighter;
  border-top: 5px solid config.$brand-color-alert;
  border-bottom: 1px solid rgba(99,102,106,0.2);
}

.top-container h2 {
  @include config.brand-header-lg;
  margin: 0 0 20px;
  text-align: center;
}

.top-container p {
  @include config.brand-text-default-regular;
  text-align: center;
  margin: 0;
}

.bottom-container {
  color: config.$brand-color-secondary;
  text-align: center;
  margin: 42px 0;
}

.bottom-container h4 {
  color: config.$brand-color-secondary;
  font-weight: config.$brand-fw-bold;
  margin: 0;
}

.bottom-container i {
 @include config.brand-text-sm-semibold ;
  margin-right: 10px;
  color: inherit;
  vertical-align: middle;
}
.bottom-container span {
  @include config.brand-text-sm-semibold ;
  color: config.$brand-color-secondary;
 }

.get-help-text {
  color: white;
  cursor: pointer;
  border: none;
  text-transform: capitalize;
  font-weight: config.$brand-fw-bold;
  color: config.$brand-color-secondary !important;
}

@media screen and (max-width: 991px) {
  .get-help-text {
    @include config.brand-header-sm;
    margin: auto;
    background-color: config.$brand-color-secondary;
    border: 10px;
    padding: 10px 20px 5px 20px;
    border-radius: 25px;
  }
  .get-help-text:hover {
    background-color: config.$brand-color-primary;
  }
  .top-container p
  {
    font-size: config.$brand-fs-text-default !important;
  }
  .bottom-container span {
     color: white;
   }
}

@media screen and (min-width: 992px) {
  .get-help-text {
    @include config.brand-header-lg;
    background: none;
    margin: auto;
  }
  .get-help-text:hover {
    background: none;
  }
}


@media (max-width: 768px) {
  .button-style {
    color: config.$brand-color-primary !important;
    font-size: 1rem;
    font-weight: bold;
  }
  .button-style:hover {
    color: #fff !important;
  }
}

