@use "sass:color";
@use "src/styles/config";

.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.lg-1 {
  width: 5% !important;
}

.chat-left-container {
  position: relative;
  box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.09),
    inset -1px 0 0 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.15);
  height: inherit;
  z-index: 1;
}

.chat-centre-container {
  position: relative;
  height: inherit;
  border-right: 1.5px solid #f0f3f2;
}

.chat-right-container {
  height: inherit;
}

.full-height {
  height: 100%;
}

@media screen and (max-width: 767px) {
  .container {
    overflow-y: auto !important;
  }
}
