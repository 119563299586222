@use "src/styles/config";

.radio-group {
  width: 100%;
}

.time-range-box {
  @include config.brand-text-default-regular;
  position: relative;
  padding: 15px;
  margin: 0 0 8px 0;
  border: 1px solid #b7bfc2;
  color: #454449;
  background: #fff;
  border-radius: 10px;
  text-align: center;
}

.time-range-quote {
  @include config.brand-text-default-regular;
  position: relative;
  padding: 15px;
  margin: 0 0 8px 10px;
  border: 1px solid #b7bfc2;
  color: #454449;
  background: #fff;
  border-radius: 10px;
}

.time-range-quote:before {
  content: '';
  position: absolute;
  border-style: solid;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
  top: 33px;
  bottom: auto;
  left: -28px;
  border-width: 1px 28px 16px 0;
  border-color: transparent #b7bfc2;
}

.time-range-quote:after {
  content: '';
  position: absolute;
  border-style: solid;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
  top: 34px;
  bottom: auto;
  left: -24px;
  border-width: 1px 24px 13px 0;
  border-color: transparent #fff;
}

.question-box {
  @include config.brand-text-lg-semibold;
  background-color: #3ab54a;
  min-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px 12px 25px 12px;
  border-radius: 12px;
  color: #fff;
}

.image-style {
  max-width: unset;
}
