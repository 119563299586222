.Spinner-animation {
  width: 100%;
  height: 50px;
  display: inline-block;
  box-shadow: 0 0 8px 0 rgba(113, 109, 109, 0.3);
  border-radius: 5px;
}

.spinner-container {
  display: inline-block;
  padding: 15px 0;
  font-size: 0.875rem;
  border-radius: 30px;
  line-height: 1.25em;
  opacity: 0.2;
  align-self: flex-start;
}

.spinner {
  margin: 0;
  width: 30px;
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: skBounceDelay 1.4s infinite ease-in-out both;
  animation: skBounceDelay 1.4s infinite ease-in-out both;
  background: rgba(0, 0, 0, 1);
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes skBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes skBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
