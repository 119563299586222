@use "sass:color";
@use "src/styles/config";

.container {
  @include config.brand-subtext-default-regular;
  display: flex;
  position: relative;
  padding: 2px 10px;
  height: 75px;
  color: #1a1919a6;
  width: 100%;
  justify-content: center;
  z-index: 10;
}

.coach-info-wrapper {
  display: flex;
  box-shadow: 0 1px 2px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.4);
  width: 45rem;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #ffffff;
}

.maximized-header-text {
  margin-top: 10px;
  text-align: left;
}

.coach-image {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.coach-info {
  margin-left: 8px;
  flex: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.coach-name {
  @include config.brand-text-lg-regular;
  padding: 16px 0 16px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: config.$brand-fw-bold;
}

// Handling mobile and tablets
@media screen and (max-width: config.$breakpoint-xl) {
  .container {
    padding: 0;
  }

  .coach-info-wrapper {
    width: 100%;
  }
}
