@use "src/styles/config";

.expansion-panel-container {
  margin: 8px 16px;
}

.expansion-panel-details-container {
  flex-wrap: wrap;
  justify-content: center;
}

.shadow-style {
  box-shadow: 3px 3px 15px 1px hsla(0, 4%, 89%, 0.7);
}

.goal-image-style {
  display: inline-block;
  height: 55px;
  width: 52.5px;
}

.goal-name-style {
  @include config.brand-subtext-default-semibold;
  margin-left: 16px;
}

.expansion-panel-summary-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.goal-details-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.info-style {
  @include config.brand-subtext-default-regular;
  text-align: center;
}

.label-style {
  padding: 0 4px;
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 5px;
}

.no-progress-label-style {
  background-color: #95979a;
  color: white;
}

.selected-label-style {
  background-color: config.$brand-color-primary;
  color: white;
}

.disabled-style {
  cursor: default !important;
}
