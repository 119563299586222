@use "sass:color";
@use "src/styles/config";

.container {
  text-align: left;
  padding-left: 20px;
  padding-right: 50px;
  padding-bottom: 10px;
}

.collapse-button:focus {
  outline: 0;
}

@media screen and (max-width: 991px) {
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.button-container {
  margin-top: 5px;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
  margin-left: 20px;
}

.contact-eap-button {
  @include config.brand-text-lg-semibold;
  display: flex;
  border: 2px solid config.$brand-color-secondary;
  border-radius: 50px;
  color: config.$brand-color-secondary;
  min-height: 36px;
  line-height: normal;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4%;
  width: 200px;
}

.contact-eap-button:focus {
  outline: none;
}

.contact-eap-button:hover {
  transition: 0.3s;
  cursor: pointer;
  background-color: config.$brand-color-secondary;
  color: #fff;
}

.eap-score {
  @include config.brand-text-lg-semibold;
  padding-left: 3%;
  text-transform: capitalize;
}

.eap-content {
  @include config.brand-subtext-default-regular;
  font-size: config.$brand-fs-subtext-default !important;
  margin-top: 15px;
  padding-left: 20px;
}

.eap-info-container {
  margin-top: 15px;
}

.contact-eap {
  @include config.brand-text-lg-semibold;
  text-align: center;
  cursor: pointer;
  padding-left: 3%;
  color: config.$brand-color-secondary;
  text-decoration: underline;
}

.line-opacity {
  opacity: 0.3;
}

.category-heading {
  @include config.brand-header-default;
  text-align: center;
  padding-top: 10%;
  padding-left: 3%;
  color: config.$brand-color-foreground-primary;
}

.category-image {
  float: right;
  padding-top: 10px;
}

.eap-image-container {
  text-align: center;
  margin-top: 15px;
}

.eap-image {
  max-height: 50px;
  max-width: 200px;
}

.alert-text {
  color: config.$brand-color-foreground-primary;
  padding: 15px 0 0 20px;
}

.margin {
  margin: 16px;
}

.eap-name-style {
  text-align: center;
}

@media screen and (max-width: 991px) {
  .contact-eap-button {
    width: 160px;
  }

  .eap-image-container {
    margin-top: 10px;
  }
}
