@use "src/styles/config";

.outer-container {
  height: inherit;
}

.container {
  display: flex;
  color: config.$brand-color-foreground-primary;
  flex-direction: column;
  flex: 1 2;
  margin: 15px 130px 10px 130px;
  height: 100%;
}

.slider-header-style {
  @include config.brand-header-default;
}

.slider-context-style {
  @include config.brand-text-default-regular;
  letter-spacing: 0.18px;
  margin: 4px 0;
}

.slider-action-description-style {
  @include config.brand-subtext-default-regular;
  margin: 2px 0;
}


@media only screen and (max-width: config.$breakpoint-md) {
  .container {
    margin: 0px 10px 0 10px;
    padding-top: 20px;
    overflow-x: hidden;
  }
  .container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .slider-header-style {
    @include config.brand-text-default-regular;
  }

  .slider-context-style {
    @include config.brand-subtext-default-regular;
  }

  .slider-action-description-style {
    @include config.brand-subtext-default-regular;
  }
}
