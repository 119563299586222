@use "src/styles/config";

.ion-tab-button-style {
    width: 20vw;
    --color: #fff;
    --color-selected: #fff;
}

.tab-text-style {
    @include config.brand-subtext-default-regular;
    white-space: nowrap;
    margin-bottom: 5px;
}

.tab-text-active-style {
    font-weight: config.$brand-fw-semibold;
}

.tab-bar-container {
    padding-top: 5px;
}
