@use "sass:color";
@use "src/styles/config";

.container {
    display: flex;
    margin-right: 14px;
    height: 50px;
    touch-action: none;
}

.tool-bar-style {
    box-shadow: 0 1px 7px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.4);
    padding-top: calc(env(safe-area-inset-top) - 10px);
}

.missing-auth-container {
    display: flex;
    margin: 0 20px 0 20px;
    height: 50px;
    width: 100%;
    justify-content: center;
}

.landing-page-content-style {
    justify-content: space-between;
}


.emergency-button-style {
    @include config.brand-subtext-default-semibold;
    align-self: center;
    display: flex;
    border-radius: 21px;
    background-color: rgb(224 32 32 / 11%);
    text-align: center;
    color: #e02020;
    padding: 9px 9px 9px 8px;
}

.logo-style {
    align-self: center;
    display: flex;
}

.logo-style-nested-screen {
    padding-left: 10px;
}

.logo-style img {
    max-width: 250px;
    height: 2.5rem;
}

.powered-by-logo-container {
    display: flex;
    align-items: center;
}

.powered-by-logo-style {
    width: 120px;
    height: 25px;
}

.back-button-style {
    display: flex;
}

.back-button-style-ios {
    margin-left: 5px;
}

.toolbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}