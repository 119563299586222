@use "src/styles/config";

.filter-icon {
  position: absolute;
  width: 100%;
  min-width: 230px;
  top: 48px;
  right: 0px;
  background: #fff;
  max-width: 250px;
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0 4px 12px 2px hsla(0, 0%, 0%, 0.12);
  z-index: 9;
  margin: 0;
  transition: all 0.8s ease;
  z-index: 100;
}

.filter-icon h5 {
  @include config.brand-subtext-default-semibold;
  text-transform: uppercase;
  color: config.$brand-color-primary;
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px 15px;
}

.filter-icon button {
  float: right;
  font-size: inherit;
  text-transform: uppercase;
  color: config.$brand-color-primary;
  font-weight: config.$brand-fw-semibold;
  padding-right: 0;
  border: none;
  background: #fff;
  cursor: pointer;
}

.filter-icon button img {
  width: auto;
  max-height: 10px;
  padding-left: 5px;
  margin-top: -3px;
}

.select-filter {
  height: 25em;
  overflow: auto;
  position: absolute;
  top: 40px;
  width: 100%;
  background: #fff;
  box-shadow: 0 20px 12px 2px hsla(0, 0%, 0%, 0.12);
  border-top: 1px solid #eee;
}

.all-check {
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #eee;
}

.all-check h6 {
  @include config.brand-subtext-default-regular;
  text-transform: uppercase;
  color: #8c8c8c;
}

.input-sec {
  position: relative;
  margin: 5px 0;
}

.input-sec input {
  cursor: pointer;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
}

.input-sec label {
  @include config.brand-subtext-default-regular;
  position: absolute;
  margin-left: 10px;
  bottom: 3px;
}

.filter-label {
  cursor: pointer;
}

.input-sec input:before {
  content: '';
  display: block;
  width: 71%;
  height: 71%;
  margin: 20% auto;
  border-radius: 200px;
}

.input-sec input:checked:before {
  background: config.$brand-color-primary;
}
