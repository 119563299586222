@use "src/styles/config";

.level-separator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
}

.level-label {
  @include config.brand-subtext-default-semibold;
  position: absolute;
  height: 16px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  padding: 2px 12px 0;
}
