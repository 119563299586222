@use 'sass:color';
@use 'src/styles/config';

.container {
  display: flex;
  flex-direction: column;
  min-height: 450px;
  flex-grow: 1;
  box-shadow: 0 0 6px 0 rgba(99, 102, 106, 0.1),
    0 3px 4px 0 rgba(99, 102, 106, 0.14) !important;
}

.header-container {
  background-color: config.$brand-color-primary;
  padding: 8px 10px;
}

.header-container h2 {
  @include config.brand-header-sm;
  color: #fff;
  margin: 0;
}

.img-style {
  height: 165px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.count-container {
  margin-top: -37px;
  align-self: flex-end;
}

.count-container div {
  background: url(../../../../assets/img/dashboard/baklinecurvesm.svg) no-repeat;
  background-position: 95%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 60px;
  padding-top: 5px;
  width: 160px;
}

.count-container div span:nth-child(1) {
  @include config.brand-header-xl;
}

.count-container div span:nth-child(2) {
  @include config.brand-subtext-default-regular;
  background-color: config.$brand-color-foreground-secondary;
  color: #fff;
  padding: 0 15px;
  text-align: center;
  border-radius: 10px;
}

.category-count-long-class {
  margin-top: 10px;
  line-height: 2em !important;
  font-size: config.$brand-fs-subtext-default !important;
  letter-spacing: 0.078rem;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.content-list-style {
  margin: 0;
  padding: 0 0 20px 20px;
}

.content-list-style li,
.content-style {
  @include config.brand-text-default-regular;
}

.status-separator-style {
  width: 65%;
  margin-left: 0;
  border-top: color.change(
    config.$brand-color-foreground-secondary,
    $alpha: 0.8
  );
}

.status-text-style {
  @include config.brand-subtext-default-regular;
}
