@use "src/styles/config";

.align-center {
  text-align: center;
}

.container h1 {
  color: #4b4c51;
}

.intro-container {
  margin-top: 30px;
}

.intro-container img {
  border-radius: 100%;
  width: 40%;
  background-color: #f7fcf8;
}

.intro-content {
  padding-top: 5%;
}

.intro-content p {
  @include config.brand-subtext-default-regular;
  margin-top: 18px;
  height: 84px;
  text-align: center;
}

.button-container button {
  width: 40%;
}

@media screen and (max-width: 991px) {
  .intro-content {
    padding-top: 6%;
  }

  .intro-content p {
    margin-left: 7%;
    margin-right: 7%;
    @include config.brand-text-default-regular;
  }

  .button-container {
    margin-bottom: 4%;
  }

  .privacy-notice-style {
    max-width: 335px;
  }

  .footer-section {
    margin-top: 20px;
  }

  .button-container button {
    width: 80%;
  }
}

.footer-section {
  padding: 20px 8px;
}
