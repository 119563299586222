.container {
  position: relative;
  height: inherit;
}

.full-screen-content {
    top: 15% !important;
}

@media only screen and (max-width: 767px) {
    .full-screen-content {
        top: 5% !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
    .full-screen-content {
        top: 8% !important;
    }
}
