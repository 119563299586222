.footer-container {
  position: absolute;
  bottom: env(safe-area-inset-bottom, 0px);
  width: 100vw;
  height: 75px;
  z-index: 100;
  border-top: 2px solid #f3f3f5;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.keyboard-open-style {
  bottom: 0;
}
