@use "sass:color";
@use "src/styles/config";

.container {
  height: 300px;
  border: 0;
  flex-direction: column;
  display: flex;
}

.eap-info-style {
  @include config.brand-subtext-default-regular;
  margin-left: 35px;
  line-height: 0;
  margin-bottom: 4px;
}

.eap-detail-text {
  @include config.brand-header-default;
  color: color.change(config.$brand-color-foreground-primary, $alpha: 0.8);
  padding-top: 12px;
  display: flex;
}

.eap-content,
.eap-phone-content {
  @include config.brand-subtext-default-regular;
  color: config.$brand-color-primary;
  padding-left: 15px;
}

.eap-content {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 500px;
  height: 1.2em;
  white-space: nowrap;
}

.eap-phone-content {
  padding-left: 12px;
}

.big-avatar {
  margin: 10px;
  max-height: 50px;
  max-width: 100px;
}

.top-div {
  flex: 1;
  background-color: rgb(226, 55, 41);
  display: flex;
  height: 0.5%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.middle-div {
  width: 100%;
  height: 40%;
  border: 0 solid darkgray;
  border-top: 5px solid rgb(226, 55, 41);
  flex-direction: row;
  display: flex;
  justify-content: center;
  background-color: rgb(221, 243, 248);
}

.middle-container {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.detail-eap-text-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
}

.emp-aText-container {
  text-align: center;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.image-logo-container {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-div-container {
  width: 100%;
  height: 55%;
  border: 0px solid darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bottom-contain-container {
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
}

.text-container {
  width: 100%;
  height: 33%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.image-style {
  width: 20px;
  height: 20px;
}

.eap-phone-icon {
  color: config.$brand-color-primary;
}

@media screen and (max-width: 991px) {
  .middle-div {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }

  .eap-phone-style {
    width: 200px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 15px auto 0 auto;
    background-color: config.$brand-color-secondary;
    border-radius: 32px;
    justify-content: center;
    align-items: center;
  }

  .eap-phone-content {
    padding-left: 5px;
    color: #fff;
  }

  .eap-phone-icon {
    color: #fff;
  }

  .eap-phone-icon:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: config.$breakpoint-md) {
  .eap-detail-text {
    padding-top: 24px;
  }
}
