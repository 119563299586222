@use "src/styles/config";

.container {
  @include config.brand-text-lg-semibold;
  background: none;
  padding: 4px 26px;
  color: config.$brand-color-secondary;
  border: 2px solid config.$brand-color-secondary;
  border-radius: 25px;
}

.container:hover {
  transition: 0.3s;
  cursor: pointer;
  background-color: config.$brand-color-secondary;
  color: #fff !important;
}

.container:disabled {
  opacity: 0.6;
}

.loading-icon-style {
  color: config.$brand-color-secondary;
}

.container:hover .loading-icon-style {
  color: #fff;
}

@media (max-width: config.$breakpoint-md) {
  .container {
    font-size: config.$brand-fs-mobile-text-default;
    color: config.$brand-color-secondary !important;
  }
}
