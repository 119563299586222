.button-wrapper {
  position: absolute;
  bottom: 75px;
  font-size: 0;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.prev-button {
  left: -48px;
}
.next-button {
  right: -48px;
}

.button-wrapper:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.prev-button:after {
  background: url(../../assets/icons/leftarrow.svg) no-repeat center;
}

.next-button:after {
  background: url(../../assets/icons/rightarrow.svg) no-repeat center;
}


