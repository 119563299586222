.prev-button {
  position: absolute !important;
  top: 60% !important;
  transform: translateY(-50%) !important;
  left: -13px !important;
  z-index: 99;
  font-size: 0;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff !important;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
}

.prev-button:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: url(../../../../../../assets/icons/leftarrow.svg) no-repeat center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.next-button:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: url(../../../../../../assets/icons/rightarrow.svg) no-repeat center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}

.next-button {
  position: absolute !important;
  top: 60% !important;
  transform: translateY(-50%) !important;
  right: -13px !important;
  z-index: 99;
  font-size: 0;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fff !important;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.15);
}
