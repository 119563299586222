@use "src/styles/config";

.text-container-first-slide {
  padding: 5% 16% 9% 11%;
  height: 100vh;
}

.text-container {
  padding: 5% 16% 9% 11%;
  position: absolute;
  width: 100%;
  top: 57%;
}

.text-container-coach {
  padding: 5% 16% 7% 11%;
  position: absolute;
  width: 100%;
  top: 43%;
}

.list-text-bottom-goal-screen {
  @include config.brand-header-default;
  line-height: 23px;
}

@media screen and (min-device-width: 1080px) {
  .slide1 {
    background: url(../../../assets/img/desktop/welcome1.jpg) no-repeat;
  }

  .slide2 {
    background: url(../../../assets/img/desktop/welcome2.jpg) no-repeat;
  }

  .slide3 {
    background: url(../../../assets/img/desktop/welcome3.jpg) no-repeat;
  }

  .slide4 {
    background: url(../../../assets/img/desktop/welcome4.jpg) no-repeat;
  }

  .slide5 {
    background: url(../../../assets/img/desktop/welcome5.jpg) no-repeat;
  }

  .slide6 {
    background: url(../../../assets/img/desktop/welcome6.jpg) no-repeat;
  }
}

.title-text {
  @include config.brand-header-xl; 
  color: #ffffff;
  padding: 15px 0;
  margin: 0;
}

.list-checkmark {
  list-style: none;
}

.li-checkmark {
  background: url(../../../assets/img/desktop/checkmark.svg) no-repeat 0 50%;
}

.li-checkmark-blue {
  background: url(../../../assets/img/desktop/checkmark_blue.svg) no-repeat;
  background-position-y: 50%;
}

.li-checkmark span,
.li-checkmark-blue span {
  margin-left: 35px;
}

.list-text {
  @include config.brand-header-lg;
  color: #ffffff;
  padding-left: 0px;
  margin: 0;
}

.list-text-coach {
  @include config.brand-text-lg-semibold;
  color: #ffffff;
  padding-left: 0px;
  margin: 0;
}

.list-text-middle {
  @include config.brand-header-default; 
  color: #ffffff;
  padding-left: 0px;
  margin: 0;
}

.list-text-bottom,
.list-text-screen-goal-screen {
  @include config.brand-header-default;
  color: #ffffff;
  padding-left: 0px;
  margin: 0;
}

.list-flex {
  display: block;
}

.message {
  width: 13em;
  @include config.brand-header-lg;
  font-weight: config.$brand-fw-semibold;
  color: #ffffff;
  padding: 15px 0;
  margin: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.bottom-center {
  align-self: center;
  text-align: center;
  position: absolute;
  bottom: 5%;
}

.single-line-text {
  width: 450px;
}

.welcome-center-text {
  width: 380px;
}

.welcome-screen-text-two,
.welcome-screen-text-three,
.welcome-screen-coach-screen-text {
  width: 740px;
  text-transform: uppercase;
}

.text-dark {
  color: config.$brand-color-foreground-primary;
}

.list-coach-margin {
  margin-top: 20px;
}

.line {
  width: 52px;
  height: 1px;
  opacity: 0.34;
  border: solid 1px #979797;
  margin-top: 10px;
  margin-bottom: 15px;
}

.justify-end {
  justify-content: flex-end;
}

@media screen and (max-height: 570px), screen and (max-width: 320px) {
  .title-text {
    @include config.brand-header-default; 
    width: 170px;
  }

  .message {
    @include config.brand-subtext-default-regular;
  }
}

@media screen and (max-width: 990px) {
  .text-container-first-slide {
    padding: 5% 8% 30% 8%;
  }

  .text-container {
    position: absolute;
    width: 100%;
    top: 57%;
  }

  .slide1 {
    background: url(../../../assets/img/mobile/welcome1.jpg) no-repeat;
  }

  .slide2 {
    background: url(../../../assets/img/mobile/welcome2.jpg) no-repeat;
  }

  .slide3 {
    background: url(../../../assets/img/mobile/welcome3.jpg) no-repeat;
  }

  .slide4 {
    background: url(../../../assets/img/mobile/welcome4.jpg) no-repeat;
  }

  .slide5 {
    background: url(../../../assets/img/mobile/welcome5.jpg) no-repeat;
  }

  .slide6 {
    background: url(../../../assets/img/mobile/welcome6.jpg) no-repeat;
  }

  .li-checkmark-blue {
    background-position-y: 17%;
  }

  .text-container-first-slide {
    padding: 5vh 8vw 18vh 8vw;
  }

  .text-container-coach {
    padding: 5vh 10vw 7vh 10vw;
    position: absolute;
    width: 100%;
    top: 43%;
  }

  .title-text {
    @include config.brand-header-lg;
    width: 170px;
  }

  .list-text {
    @include config.brand-subtext-default-semibold;
  }

  .list-text-middle {
    @include config.brand-text-default-regular;
    font-weight: config.$brand-fw-bold !important;
    line-height: 23px;
  }

  .list-text-bottom {
    @include config.brand-subtext-default-regular;
  }

  .list-text-bottom-goal-screen {
    @include config.brand-subtext-default-regular;
  }

  .list-text-coach {
    @include config.brand-subtext-default-regular;
  }

  .li-checkmark {
    background: url(../../../assets/img/mobile/checkmark.png) no-repeat 0 50%;
  }

  .list-flex {
    display: flex;
  }

  .single-line-text,
  .welcome-screen-text-two,
  .welcome-screen-text-three {
    width: auto;
    white-space: normal;
  }

  .welcome-screen-coach-screen-text {
    width: 262px;
  }

  .welcome-center-text {
    width: 280px;
  }

  .message {
    @include config.brand-text-lg-regular;
  }

  .bottom-center {
    bottom: 22vh;
  }
}

@media screen and (max-width: 361px) {
  .list-text-middle,
  .list-text-bottom {
    @include config.brand-subtext-default-regular;
  }

  .welcome-screen-text-three {
    width: 290px;
  }

  .bottom-center {
    bottom: 130px;
  }

  .list-text-coach {
    @include config.brand-subtext-default-regular;
    line-height: 21px !important;
  }

  .list-text-screen-goal-screen {
    font-size: 0.92rem;
    line-height: 23px;
    width: max-content;
  }

  .li-checkmark-blue {
    background-position-y: 17%;
  }
}

@media screen and (max-width: 411px), screen and (max-height: 667px) {
  .bottom-center {
    bottom: 20vh;
  }

  .text-container-first-slide {
    padding: 5vh 8vw 13vh 8vw;
  }

  .text-container {
    position: absolute;
    width: 100%;
    top: 57%;
  }

  .text-container-coach {
    padding: 5vh 10vw 5vh 10vw;
    width: 100%;
    position: absolute;
    top: 43%;
  }
}

@media screen and (max-width: 411px), screen and (max-height: 640px) {
  .text-container-first-slide {
    padding: 5vh 8vw 13vh 11vw;
  }

  .text-container {
    position: absolute;
    width: 100%;
    top: 57%;
  }

  .text-container-coach {
    padding: 5vh 10vw 8vh 10vw;
    position: absolute;
    width: 100%;
    top: 43%;
  }
}

@media screen and (max-width: 411px), screen and (max-height: 736px) {
  .bottom-center {
    bottom: 25vh !important;
  }
}

@media screen and (max-width: 411px), screen and (max-height: 812px) {
  .bottom-center {
    bottom: 24vh !important;
  }

  .text-container-first-slide {
    padding: 5vh 8vw 18vh 11vw;
  }

  .text-container {
    justify-content: flex-end;
  }

  .text-container-coach {
    padding: 5vh 8vw 13vh 11vw;
    position: absolute;
    width: 100%;
    top: 43%;
  }
}

@media screen and (min-width: 500px) {
  .text-container-first-slide {
    padding: 5vh 8vw 13vh 11vw;
  }

  .bottom-center {
    bottom: 7vh !important;
  }

  .text-container-coach {
    padding: 5vh 10vw 8vh 10vw;
    position: absolute;
    width: 100%;
    top: 43%;
  }
}

.slide-background {
  background-size: cover;
  height: 100vh;
  background-position-y: center;
  background-position-x: center;
}

.slide-background-first-screen {
  background-size: cover;
  height: 100vh;
  background-position-y: bottom;
  background-position-x: center;
}

/* FOR IPAD AND IPAD PRO */
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .bottom-center {
    bottom: 13vh !important;
  }
  .slide-background {
    background-position: 0% 89%;
  }

  .slide-background-default {
    background-position: 0% 30%;
  }

  .text-container {
    position: absolute;
    width: 100%;
    top: 62%;
  }

  .text-container-coach {
    position: absolute;
    top: 47%;
  }

  .welcome-screen-coach-screen-text {
    width: 70%;
  }

  .slide1 {
    background: url(../../../assets/img/tablet/welcome1.jpg) no-repeat;
    background-size: cover;
    background-position: 0% 92%;
  }

  .slide2 {
    background: url(../../../assets/img/tablet/welcome2.jpg) no-repeat;
  }

  .slide3 {
    background: url(../../../assets/img/tablet/welcome3.jpg) no-repeat;
  }

  .slide4 {
    background: url(../../../assets/img/tablet/welcome4.jpg) no-repeat;
  }

  .slide5 {
    background: url(../../../assets/img/tablet/welcome5.jpg) no-repeat;
  }

  .slide6 {
    background: url(../../../assets/img/tablet/welcome6.jpg) no-repeat;
  }

  .slide2,
  .slide3,
  .slide4,
  .slide5,
  .slide6 {
    background-size: cover;
    background-position: 0% 30%;
  }

  .list-text,
  .message {
    @include config.brand-header-default; 
  }
  .list-text-middle {
    width: 110%;
    @include config.brand-header-lg;
  }
  .list-text-bottom {
    @include config.brand-header-lg;
  }
  .list-text-coach {
    @include config.brand-header-default; 
    width: 109%;
  }
  .list-text-bottom-goal-screen {
    @include config.brand-header-default; 
  }
  .title-text {
    width: 70%;
  }
  .welcome-center-text {
    width: 50%;
  }
  .single-line-text {
    width: 557px;
  }
  .line {
    width: 52px;
    height: 1px;
    opacity: 0.34;
    border: solid 2px #979797;
    margin-top: 20px;
    margin-bottom: 25px;
  }
}
