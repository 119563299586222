@use "src/styles/config";

.img {
  width: 100%;
}

.quote-container {
  display: flex;
  padding-left: 5%;
  justify-content: center;
  text-align: center;
  min-height: 200px;
  flex-direction: column;
}

.quote-container p {
  @include config.brand-text-lg-regular;
}

.quote-box {
  width: 70%;
  margin: 0 0 0 25px;
  border: 1px solid #b7bfc2;
  padding: 10px;
  border-radius: 10px;
}

.quote {
  width: 80%;
  position: relative;
  padding: 15px;
  margin: 0 0 1em 1em;
  border: 1px solid #b7bfc2;
  color: config.$brand-color-foreground-primary;
  background: #fff;
  text-align: left;
  border-radius: 10px;
}

.quote:before {
  content: '';
  position: absolute;
  border-style: solid;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
  top: 10px; /* controls vertical position */
  bottom: auto;
  left: -26px;
  border-width: 1px 25px 16px 0;
  border-color: transparent #b7bfc2;
}

.quote:after {
  content: '';
  position: absolute;
  border-style: solid;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
  top: 11px; /* value = (:before top) + (:before border-top) - (:after border-top) */
  bottom: auto;
  left: -22px;
  border-width: 1px 23px 13px 0;
  border-color: transparent #fff;
}

.next-button-container {
  margin: 50px auto 0;
  padding: 20px 50px;
}

.container {
  margin-top: 30px;
  margin-bottom: 50px;
}

.connector {
  width: 3px;
  height: 35px;
  margin-left: 60px;
}

.bold-text {
  @include config.brand-header-default;
}

@media screen and (max-width: 450px) {
  .next-button-container {
    border-top: 2px solid rgba(211, 211, 211, 0.3);
  }
}

@media screen and (max-width: 991px) {
  .img {
    margin-top: 15px;
  }

  .quote {
    @include config.brand-header-default;
  }

  .quote-box {
    width: 100%;
  }

  .quote {
    width: 100%;
  }

  .quote-container {
    padding-left: 0;
  }
}

.aligned-button:active,
.aligned-button:focus,
.aligned-button:hover {
  color: config.$brand-color-secondary !important;
  text-decoration: underline;
}
