@use "src/styles/config";

.container {
  position: relative;
  overflow: scroll;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background: config.$brand-companySelect-container-background;
  background-size: contain, contain, contain;
}

.powered-by-logo-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  bottom: 30px;
  right: 30px;
}

.powered-by-logo-style {
  width: 9rem;
  height: 2rem;
}

@media screen and (max-width: config.$breakpoint-xl) {
  .powered-by-logo-container {
    padding: 20px;
    bottom: 0;
    right: 0;
    justify-content: center;
  }

  .powered-by-logo-style {
    width: 8rem;
  }
}
