@use "src/styles/config";

.question {
  @include config.brand-text-lg-semibold;
  text-align: center;
  width: 60%;
  margin: 20px auto 0 auto;
}

.options-container {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 991px) {
  .question {
    width: 100%;
  }
}
