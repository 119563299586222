@use "src/styles/config";

.card-style {
  @include config.brand-text-default-regular;
  background-color: #f1fbfc;
  padding: 16px 24px;
  margin-bottom: 64px;
  color: #297275;
  border-radius: 16px;
  width: 100%;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
