@use "src/styles/config";

.system-message-style,
.user-message-style {
  @include config.brand-text-default-regular;
  display: flex;
  flex-direction: column;
  max-width: 85%;
}

.system-message-style {
  align-self: flex-start;
}

.user-message-style {
  align-self: flex-end;
}

.choices-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  margin-top: 4px;
}

.choice-style {
  width: max-content;
  align-self: flex-end;
}

.choice-button-style {
  @include config.brand-text-lg-semibold;
  border: 2px solid config.$brand-color-primary !important;
  color: config.$brand-color-primary;
  margin-top: 0;
}

.choice-button-style span {
  position: relative;
  bottom: 2px;
}

@media screen and (max-width: config.$breakpoint-md) {
  .choice-button-style {
    font-size: config.$brand-fs-mobile-text-sm !important;
    line-height:0.75rem;
    padding: 4px 16px;
    margin-bottom: 3px;
  }

  .choice-button-style span {
    bottom: 0;
  }
}

.choice-button-style:hover {
  background: config.$brand-color-primary;
  color: #fff;
  border: 2px solid config.$brand-color-primary !important;
}

.error-button-style {
  border: none;
  background: none;
  margin: 0 0 0 3px;
  padding: 0;
  color: config.$brand-color-primary;
}

.error-button-style:hover {
  cursor: pointer;
  text-decoration: underline;
}
