@use "src/styles/config";

.container {
  position: relative;
  padding: 2rem 4rem;
  overflow-y: auto;
  width: 100%;
  height: inherit;
}

.top-panel-style {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding-bottom: 8px;
}

.content-style{
  padding-top: 1rem;
  text-align: justify;
}

.heading-style {
  @include config.brand-header-lg;
}

.image-style {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.description-style {
  padding: 1rem 0 1.5rem;
  text-align: justify;
}

@media only screen and (max-width: 991px) {
  .heading-style {
    @include config.brand-header-sm;
  }
}


@media only screen and (max-width: config.$breakpoint-md) {
  .top-panel-style {
      flex-direction: column;
  }

  .heading-style {
    @include config.brand-header-lg;
  }
}