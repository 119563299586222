@use "sass:map";

$grey-lightest: #fafafa;
$grey-light-medium: #fbfcfc;
$blue: #03a0b5;
$orange: #faad14;

// goal severity colors
$goal-severity: (
  high: #f15037,
  moderate: #ffc332,
  low: #8dc74d,
  bonus: #48cfdf
);

:export {
  colorGoalSeverityHigh: map.get($goal-severity, high);
  colorGoalSeverityModerate: map.get($goal-severity, moderate);
  colorGoalSeverityLow: map.get($goal-severity, low);
  colorGoalSeverityBonus: map.get($goal-severity, bonus);
}
