@use "src/styles/config";

.tool-item-container {
  @include config.brand-subtext-default-regular;
  padding-top: 8px;
  width: 100%;
}

.text-style {
  padding-top: 8px;
  padding-right: 8px;
}

.bold-style {
  font-weight: config.$brand-fw-bold;
}

.header-style {
  @include config.brand-text-default-regular;
}

.row-style {
  display: table-row;
}

.cell-style {
  display: table-cell;
}

.label-style {
  @include config.brand-subtext-default-regular;
  flex-shrink: 0;
  margin-top: 8px;
  border-radius: 50%;
  height: 21px;
  width: 21px;
  text-align: center;
  line-height: 22px;
  background-color: #95979a;
  color: white;
  transform: translateY(1px);
}

.label-item-container {
  display: flex;
  justify-content: space-between;
}

.list-style {
  margin-bottom: 0;
}
