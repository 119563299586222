@use "src/styles/config";

.offline-text {
  background-color: config.$brand-color-alert;
  width: 100%;
  height: 32px;
  color: #fff;
  border-radius: 0 0 5px 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 98;
  position: absolute;
  top: 73px;
}
