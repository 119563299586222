.skeleton-wrapper {
  margin: 0 auto 0 auto;
}

.sk-outer {
  padding-left: 25%;
}

@media screen and (max-width: 767px) {
  .sk-outer {
    padding-left: 0 !important;
    width: 100%;
    height: auto;
  }
}
