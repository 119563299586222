@use 'src/styles/config';

.container {
  max-width: 380px;
  min-height: 304px;
  box-shadow: 0 0 6px 0 rgba(99, 102, 106, 0.1),
    0 3px 4px 0 rgba(99, 102, 106, 0.2) !important;
}

.content-container {
  display: flex;
  min-height: 231px;
}

.content-container img {
  object-fit: cover;
  width: 94px;
}

.content-container div {
  padding: 20px 18px 0 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content-container h2 {
  @include config.brand-header-default;
  padding-right: 14px;
}

.content-container p {
  @include config.brand-text-default-regular;
  color: config.$brand-color-foreground-primary;
  text-overflow: ellipsis;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 18px 16px 15px;
  border-top: 1px solid rgba(151, 151, 151, 0.4);
}
