@use "src/styles/config";

.container {
  position: relative;
  margin: 5% auto;
  background: #fff;
  max-width: 550px;
  border-radius: 6px;
  padding: 40px 60px;
}

.form-field {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.array-field-style {
  margin-top: 0 !important;
}

.register-button {
  margin: 20px auto 10px;
  width: auto;
  background: config.$brand-color-secondary;
  color: #fff;
  padding: 15px 45px;
}

.login-button-container {
  margin-top: 10px;
  text-align: right;
}

.login-button-container span {
  @include config.brand-subtext-default-regular;
}

.login-button-container button {
  @include config.brand-text-sm-semibold;
  margin-left: 12px;
  padding-left: 20px;
  padding-right: 20px;
  height: 25px;
  text-transform: uppercase;
}

.text {
  color: config.$brand-color-foreground-primary;
}

.full-width {
  width: 100% !important;
  text-align: left;
}

.heading-text {
  @include config.brand-header-lg;
  margin-bottom: 20px;
  text-align: center;
}

.error-container {
  width: 350px;
  margin: 0 auto 10px auto;
  text-align: left;
}
.error-container > div {
  padding: 5px 5px;
}
.register-terms-and-policy-container {
  @include config.brand-subtext-default-regular;
  width: 100%;
  text-align: center;
}

.align-center {
  text-align: center;
}

.Policy-container-profile {
  margin-top: 2%;
}

.back-button-style {
  position: absolute;
  left: 3%;
  top: 3%;
  cursor: pointer;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: #757575;
}

@media screen and (max-width: 991px) {
  .register-button {
    margin-top: 20px;
  }

  .login-button-container {
    text-align: center;
    padding-bottom: 50px;
    padding-top: 10px;
  }

  .register-terms-and-policy-container {
    width: 100%;
    @include config.brand-subtext-default-regular;
    text-align: center;
  }

  .back-button-style {
    top: 2%
  }
}

@media screen and (max-width: 680px) {
  .container {
    margin: 5% auto;
    background: #fff;
    max-width: 310px;
    border-radius: 6px;
    padding: 30px 20px;
  }

  .heading-text {
    @include config.brand-header-default;
  }
  .register-button {
    padding: 15px 35px;
  }
}

.link {
  color: config.$brand-color-secondary;
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  color: #ff8d7b;
  cursor: pointer;
  text-decoration: underline;
}

.alert-container {
  display: flex;
}