@use "src/styles/config";

.edit-field-container {
    position: relative;
    width: 100%;
}

.edit-field-style {
    display: -webkit-box;
    @include config.brand-text-default-regular;
    color: config.$brand-color-foreground-secondary;
    align-items: center;
    border: 2px solid #03a0b5;
    border-radius: 10px;
    width: 100%;
    height: 65px;
    padding: 15px;
    cursor: pointer;
    margin-right: 20px;
    resize: none;
    outline: none;
}
.text-input-height-style {
    height: 100px;
}
.edit-field-action-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 3px;
}

.cancel-icon-style {
    color: lightgrey;
    cursor: pointer;
    margin-right: 5px;
}

.completed-icon-style {
    width: 22px;
    cursor: pointer;
}

.invalid-input-border-style {
    border: 2px solid config.$brand-color-alert;
}

.text-error-style {
    @include config.brand-subtext-default-regular;
    align-self: flex-start;
    flex: 1;
    color: config.$brand-color-alert;
    margin-left: 10px;
}
.action-button-container {
    display: flex;
    align-items: flex-start;
}

@media screen and (min-width: config.$breakpoint-md) {
    .edit-field-style {
        width: 100%;
    }
}
  
  