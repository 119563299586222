@use "sass:color";
@use "sass:map";
@use "src/styles/config";

.container {
  position: relative;
}

.header-container {
  text-align: center;
  background-color: #3ab54a;
  width: 100%;
  height: 50px;
}

.header-container h3 {
  @include config.brand-header-lg;
  color: #fff;
  padding-top: 5px;
}

.expansion-panel-container {
  margin: 10px 20px;
}

.panel {
  box-shadow: 3px 3px 15px 1px hsla(0, 4%, 89%, 0.7);
  border-radius: 5px;
  color: rgba(76, 76, 78, 0.5);
  background-color: #ffff;
  margin-top: 2%;
}

.bottom-link-container {
  margin-bottom: 30px;
  text-align: center;
}

.report-text {
  margin-bottom: 0px !important;
  padding-bottom: 1rem !important;
  color: #fff !important;
}

.bottom-link-container a {
  font-weight: config.$brand-fw-bold;
}

.link {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
  margin-top: 22%;
}

.logout-link {
  text-align: right;
}

.retake-link {
  text-align: center;
}

.try-again-container {
  text-align: center;
  width: 100%;
  padding-bottom: 30px;
}

.report-timestamp-style {
  color: rgba(76, 76, 78, 0.7);
  margin: 5px 20px 0 0;
  float: right;
}

@media screen and (max-width: 991px) {
  .note {
    width: 90%;
  }

  .goals-header-content {
    position: relative;
    bottom: 45px;
    width: 90%;
    padding-top: 10%;
  }

  .goals-header {
    position: relative;
    top: 15px;
    background-size: contain !important;
    height: 365px !important;
    background-position-y: bottom;
  }

  .header-style {
    @include config.brand-header-lg;
  }
}

.bonus {
  background-color: map.get(config.$color-goal-severity, bonus);
}

.high {
  background-color: map.get(config.$color-goal-severity, high);
}

.medium {
  background-color: map.get(config.$color-goal-severity, moderate);
}

.low {
  background-color: map.get(config.$color-goal-severity, low);
}

.goals-header {
  background-size: cover;
  background-image: url(./../../assets/img/goals_list_background.png);
  background-repeat: no-repeat;
  margin-top: 1px;
  height: 250px;
}

.goals-header-content {
  padding-left: 10%;
  padding-right: 20%;
}

.header-style {
  @include config.brand-header-lg;
  font-style: normal;
  font-stretch: normal;
}

.goals-header-section {
  padding-top: 10%;
  font-style: normal;
  font-stretch: normal;
  background-color: config.$brand-color-primary;
}

.header-desc-style {
  @include config.brand-subtext-default-regular;
  color: #ffffff;
  padding-top: 5px;
}

@media screen and (min-width: 600px) {
  .goals-header {
    background-image: url(./../../assets/img/desktop/goal_list_background_desktop.png);
    background-size: 121vw 20em;
    height: 20em;
    background-repeat: no-repeat;
    border-bottom: 1px solid white;
    /* width: 100%; */
    margin-left: -5%;
  }

  .goals-header-section {
    padding-top: 5%;
  }
}

.report-footer-image {
  background-size: 188vw;
  background-image: url(./../../assets/img/survey_background.svg);
  height: 24em;
  width: 100vw;
  background-position: center top;
  color: #ffffff;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  background-repeat: no-repeat;
}

.view-all-results {
  @include config.brand-subtext-default-semibold;
  background: none;
  border: none;
  width: 213px;
  height: 7vmin;
  color: #fff;
  text-decoration: underline;
  padding-top: 10px;
}

.footer-text {
  @include config.brand-subtext-default-regular;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
}

.collapse-div {
  /* height: 100px; */
}

.display-none {
  display: none;
}

@media screen and (min-width: 600px) {
  .link {
    margin-top: 22%;
  }

  .footer-text {
    padding-left: 23%;
    padding-right: 23%;
  }

  .header-desc-style {
    @include config.brand-subtext-default-regular;
    font-size:config.$brand-fs-text-sm !important;
    line-height:1.25rem;
    color: #ffffff;
    max-width: 90%;
    padding-left: 27%;
    margin-top: 10px;
  }

  .header-style {
    padding-left: 27%;
  }
}

.eap-detail-text {
  @include config.brand-header-default; 
  color: black;
  
  font-stretch: normal;
  padding-top: 15%;
  /*padding-right: 3%;*/
  display: flex;
}

.ant-modal-body {
  @include config.brand-subtext-default-regular;
  padding: 0px !important;
  word-wrap: break-word;
}

.eap-content {
  @include config.brand-subtext-default-semibold;
  padding-top: 3%;
  color: config.$brand-color-primary;
  padding-left: 7%;
}

.big-avatar {
  margin: 10px;
  width: 70%;
  height: 60%;
}

.retake-assessment-button {
  @include config.brand-subtext-default-semibold;
  padding: 10px 35px;
  border-radius: 30px;
  background-color: #ffffff;
  color: config.$brand-color-primary;
  letter-spacing: 1px;
  border: unset;
}
