@use "src/styles/config";


.tab-section-outer {
  height: calc(100vh - 322px);
  overflow: auto;
  position: absolute;
  top: 230px;
  width: 100%;
}

.tab-section {
  width: 100%;
  display: block;
}

.tab-list {
  width: 100%;
  float: left;
  border-top: 2px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 12px 20px;
  margin-bottom: 15px;
}

.tab-title {
  color: #9b9ea0;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: config.$brand-fw-semibold;
  display: inline-block;
  letter-spacing: 1px;
  margin-right: 8%;
  cursor: pointer;
  position: relative;
  outline: inherit;
}

.tab-title:hover::before {
  content: '';
  width: 100%;
  height: 4px;
  background: config.$brand-color-secondary;
  bottom: -12px;
  left: 0;
  position: absolute;
  border-radius: 70px 70px 0 0px;
}

.tab-title:hover {
  color: config.$brand-color-secondary;
}

.tab-title:last-child {
  margin-right: 0;
}

.tab-panel {
  width: 100%;
  padding: 10px;
}

.tab-panel .items-sec {
  list-style: none;
  width: 100%;
  padding: 10px 20px 10px 10px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  position: relative;
}

.indicator-color {
  text-align: left;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 15px;
}

.heading-style {
  font-size: 0.93rem;
  color: #66686b;
  text-transform: capitalize;
  font-weight: config.$brand-fw-bold;
  display: inline-block;
  margin-left: 12px;
  vertical-align: middle;
  line-height: 20px;
}

.heading-style p {
  font-size: 0.625rem;
  text-transform: uppercase;
  color: #9b9ea0;
  margin-bottom: 0;
}

.img-style {
  display: inline-block;
  width: 42px;
  height: 42px;
  margin-left: 10px;
}

.tab-panel ul li .down-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.score-sec {
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 10px;
  font-size: 0.75rem;
}

.score-sec p {
  width: auto;
  display: inline-block;
  color: rgb(246, 187, 66);
  margin: 0;
  font-weight: config.$brand-fw-semibold;
  font-size: 0.75rem;
}

:global(.react-tabs__tab--selected) {
  color: config.$brand-color-secondary;
}

:global(.react-tabs__tab--selected)::before {
  content: '';
  width: 100%;
  height: 4px;
  background: config.$brand-color-secondary;
  bottom: -12px;
  left: 0;
  position: absolute;
  border-radius: 70px 70px 0 0px;
}

.client-act {
  width: 100%;
  display: block;
  clear: both;
}

.client-act :global(.rc-collapse-header) {
  height: auto !important;
  line-height: normal !important;
  text-indent: inherit !important;
  padding: 0 !important;
  font-weight: config.$brand-fw-semibold;
  text-transform: uppercase;
  font-size: 0.75rem;
}

.client-act :global(.rc-collapse) {
  background: #fff !important;
  border: 0 !important;
  border-radius: 0px !important;
  position: relative;
  padding: 15px 10px;
  border-top: 1px solid #eee !important;
}

.client-act :global(.rc-collapse-item) {
  border: 0 !important;
}

.client-act :global(.arrow) {
  border-top: 5px solid transparent !important;
  border-bottom: 5px solid transparent !important;
  border-left: 7px solid #8e8e8e !important;
  margin-right: 12px;
}

.client-act :global(.rc-collapse-item-active .arrow) {
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-top: 8px solid #8e8e8e !important;
}

.client-act :global(.accordion__panel) {
  padding: 0;
}

.client-act :global(.accordion) {
  border: 0 !important;
}

.client-act :global(.accordion__item) {
  position: relative;
  border: 1px solid #eee !important;
  border-radius: 4px;
  margin: 8px 0;
}

.client-act :global(.accordion__button) {
  padding: 0;
  background-color: #fff !important;
}

.client-act :global(.accordion__button:before) {
  position: absolute;
  right: 14px;
  top: 28px;
  transform: rotate(-136deg);
  height: 8px;
  width: 8px;
  opacity: 0.5;
}

.note-sec {
  width: 100%;
}

.note-top {
  width: 100%;
  text-align: right;
  margin-bottom: 15px;
}

.note-top textarea {
  width: 100%;
  border: 2px solid config.$brand-color-primary;
  padding: 15px;
  border-radius: 6px;
  resize: none;
  outline: inherit;
}

.note-top .Save-btn {
  display: inline-block;
  background: config.$brand-color-secondary;
  border-radius: 50px;
  padding: 6px 22px;
  color: #fff;
  font-size: 0.875rem;
}

.note-top .Close-btn {
  display: inline-block;
  float: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #ddd;
  line-height: 0;
  margin-right: 10px;
}

.note-top .Close-btn img {
  width: 10px;
}

.add-note {
  width: 100%;
  background: #f7f7f7;
  padding: 15px;
  text-align: left;
  border-radius: 5px;
  margin-bottom: 15px;
  padding-left: 50px;
  position: relative;
  color: #8a8a8a;
  font-size: 0.93rem;
}

.add-note::before {
  content: '';
  position: absolute;
  left: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  background: url(../../../../../assets/icons/addblue.svg) no-repeat center;
  background-size: 15px;
}

.note-sec :global(.accordion__button:before) {
  position: absolute;
  right: 14px;
  top: 25px;
  transform: rotate(-136deg);
  height: 8px;
  width: 8px;
  opacity: 0.5;
}

.note-sec :global(.accordion__item) {
  position: relative;
  border-radius: 4px;
  margin: 8px 0;
  border: 1px solid #eee;
  padding: 5px;
}

.note-sec :global(.accordion) {
  border-radius: 4px;
  border: 0;
}

:global(.accordion__button[aria-expanded='true']::before) {
  transform: rotate(45deg) !important;
}

:global(.accordion__button[aria-selected='true']::before) {
  transform: rotate(45deg) !important;
}

.note-sec :global(.accordion__button) {
  padding: 10px 15px;
  background-color: #fff !important;
  font-size: 0.875rem;
  color: #888;
  font-weight: 400;
}

.note-sec :global(.accordion__panel) {
  padding: 0 15px 15px 15px;
  font-size: 0.875rem;
  line-height: 24px;
  color: #888;
}

.reminder-sec {
  width: 100%;
  text-align: right;
}

.reminder-btn {
  background: config.$brand-color-secondary;
  border-radius: 50px;
  padding: 8px 20px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1px;
  width: 100%;
}

.reminder-btn img {
  width: auto;
  max-height: 16px;
  margin-right: 10px;
}

.input-style,
.textarea-style {
  width: 100%;
  background: #f7f7f7;
  padding: 15px;
  box-shadow: none;
  outline: inherit;
  border-radius: 6px;
  margin: 15px 0;
  resize: none;
  font-weight: config.$brand-fw-semibold;
  border: 2px solid #f7f7f7;
  transition: all 0.5s ease;
}

.input-style:focus,
.textarea-style:focus {
  background: #fff;
  border: 2px solid config.$brand-color-primary;
  transition: all 0.5s ease;
}

.input-date,
.input-time {
  border: 0;
  border-bottom: 2px solid #eee;
  outline: inherit;
  padding: 10px 0;
  width: 100%;
  font-weight: config.$brand-fw-semibold;
}

.input-date {
  padding-right: 30px;
}

.input-outer-date {
  position: relative;
}

.input-outer-date:after {
  width: 30px;
  height: 30px;
  background: url(../../../../../assets/icons/date.svg) center no-repeat;
  opacity: 0.5;
  content: '';
  position: absolute;
  right: 5px;
  top: 12px;
  background-size: 20px;
  pointer-events: none;
}

:global(.react-datepicker-wrapper) {
  width: 100%;
}

:global(.react-datepicker__input-container) {
  width: 100%;
}

:global(.react-datepicker__time-container) {
  width: 100%;
}

:global(.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box) {
  width: 100px;
}

:global(.react-datepicker) {
  top: 20px;
}

:global(.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list) {
  height: calc(135px + (1rem / 2)) !important;
}

.input-outer {
  width: 100%;
}

.input-outer-date {
  width: 48%;
  float: left;
  padding: 5px 5px 5px 15px;
}

.input-outer:nth-child(3) {
  width: 48%;
  float: right;
  padding: 5px 15px 5px 5px;
}

.add-remind .save-btn {
  display: inline-block;
  background: config.$brand-color-secondary;
  border-radius: 50px;
  padding: 6px 22px;
  color: #fff;
  font-size: 0.875rem;
}

.add-remind .close-btn {
  display: inline-block;
  float: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid #ddd;
  line-height: 0;
  margin-right: 10px;
}

.add-remind .close-btn img {
  width: 10px;
}

.add-remind {
  width: 100%;
  margin-bottom: 20px;
}

.remider-list {
  text-align: left;
  width: 100%;
}

.remider-list h6 {
  font-size: 0.93rem;
  font-weight: config.$brand-fw-semibold;
  color: config.$brand-color-primary;
  margin: 0;
}

.remider-list p {
  color: #a3a5a8;
  font-size: 0.93rem;
  line-height: 24px;
  margin: 0;
}

.list-boxe {
  width: 100%;
  position: relative;
  margin: 20px 0;
}

.list-boxe Label {
  display: inline-block;
  padding-left: 5px !important;
}

.list-boxe Label::before {
  top: 3px !important;
  border-radius: 50% !important;
}

.list-boxe Label::after {
  top: 8px !important;
}

.edit-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.edit-btn img {
  width: 16px;
  opacity: 0.5;
}

.remid-list-sec {
  padding: 0 30px;
  display: inline-block;
  border-bottom: 1px solid #eee;
}

.date-border,
.date-red {
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 0.875rem;
  color: #8a8a8a;
  margin: 10px 0;
}

.date-red {
  background: #fdf4f2;
  color: #ea7a68;
}

@media screen and (max-width: 1290px) {
  .tab-title {
    margin-right: 12px;
  }
}

@media screen and (max-width: 1250px) {
  .tab-title {
    letter-spacing: 0;
  }
}

@media screen and (max-width: 1140px) {
  .tab-title {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1080px) {
  .tab-title {
    margin-right: 7px;
  }
}

.main-tab {
  height: inherit;
  overflow: auto;
}

.main-tab :global(.react-tabs__tab) {
  @include config.brand-subtext-default-regular;
  border: 1px solid #eee;
  border-left: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  flex: 1;
  padding: 19px 12px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  color: #b3b3b5;
  text-transform: uppercase;
}

.main-tab :global(.react-tabs__tab-list) {
  display: flex;
}

.main-tab :global(.react-tabs__tab--selected) {
  color: #4a4b50;
  background-color: #fff;
}

.main-tab :global(.react-tabs__tab-panel--selected) {
  height: calc(100vh - 160px);
  overflow: auto;
}

.main-tab :global(.react-tabs__tab--selected::before),
.main-tab :global(.react-tabs__tab--selected::after) {
  width: 0px;
  height: 0px;
}

.main-tab :global(.react-tabs__tab:focus) {
  box-shadow: none;
}

.media-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 5px;
}

.media-card-container {
  box-shadow: 3px 3px 6px #ccc;
  width: 46%;
  margin: 6px;
  border-radius: 5px;
}

.tab-list-style {
  display: flex;
  padding: 0;
  border: 0;
  margin: 0;
}

.tab-style {
  list-style: none;
  outline: none;
  position: relative;
  flex: 1 1;
  text-align: center;
  cursor: pointer;
  padding: 19px 12px;
  color: #7f8487;
  font-weight: config.$brand-fw-semibold;
  letter-spacing: 1.1px;
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  text-transform: uppercase;
}

.selected-tab-style {
  color: #fd715b;
}

.selected-tab-style::before {
  content: '';
  height: 4px;
  position: absolute;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  left: 5px;
  right: 5px;
  bottom: -2px;
  background: #fd715b;
}

.tab-panel-style {
  height: min-content !important;
  padding: 10px 0;
}

.selected-tab-panel-style {
  overflow: auto;
  padding-bottom: 16px;
}

.arrow-back-container-style {
  align-items: center;
  display: flex;
  width: 15vw;
  padding: 10px;
}
