.container {
  border-radius: 50%;
  min-height: 64px;
  min-width: 65px;
  margin-bottom: 32px;
  background-color: #17b6cc;
  text-align: center;
}

.icon-style {
  color: white;
  transform: translateY(11px);
}
