@use "src/styles/config";

.custom-input {
  border-radius: 3px;
  @include config.brand-text-default-regular;
}

.add-input-container {
  background-color: #f6f7f7;
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 80px;
  padding: 15px;
  cursor: pointer;
  margin-bottom: 20px;
}

.circle {
  display: flex;
  background-color: config.$brand-color-background-primary-light;
  border: 1px solid config.$brand-color-foreground-primary;
  padding: 3px;
  border-radius: 100%;
}

.add-input-container p {
  position: relative;
  margin: 5px;
  left: 1em;
}

.custom-input-container {
  display: flex;
  flex-direction: column;
}

.add-icon {
  color: config.$brand-color-foreground-primary;
  font-weight: config.$brand-fw-semibold;
}

@media screen and (min-width: config.$breakpoint-md) {
  .add-input-container {
    width: 100%;
  }

  .add-input-container,
  .custom-input-container {
    margin-right: 20px;
  }
}
