@use "src/styles/config";

.coach-request-modal-container {
  position: relative;
  background: #ffffff;
  margin: 0px auto;
  text-align: center;
  border-radius: 9px;
  width: 100%;
}

.coach-request-container {
  position: relative;
  background: #ffffff;
  margin: 20px auto;
  text-align: center;
  border-radius: 9px;
  width: 450px;
}

.confirmation-icon {
  text-align: center;
}

.confirmation-icon img {
  height: 42px;
  object-fit: contain;
}

.coach-request-modal-top-panel {
  background-color: config.$brand-color-background-primary-light;
  padding: 40px;
  border-radius: 9px;
  margin: auto;
  width: 100%;
}

.coach-request-top-panel {
  background-color: config.$brand-color-background-primary-light;
  padding: 20px;
  border-radius: 9px;
  margin: auto;
  width: 100%;
}

.error-icon {
  color: config.$brand-color-alert;
  font-size: 3.125rem !important;
}

@media screen and (max-width: 767px) {
  .coach-request-container {
    width: 350px;
  }
}
