@use "src/styles/config";

.wrapper {
  @include config.brand-subtext-default-regular;
  width: 100%;
}

.step-progress {
    position: relative;
    padding-left: 45px;
    list-style: none;
    
    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      width: 10px;
      height: 100%;
      border-left: 2px dotted #ccc;
    }
    
    &-item {
      position: relative;
      list-style: none;
      &:not(:last-child) {
        padding-bottom: 20px;
      }
      
      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -32px;
        height: 100%;
        width: 10px;
      }
      
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: -37px;
        width: 12px;
        height: 12px;
        border: 2px solid #CCC;
        border-radius: 50%;
        background-color: #FFF;
      }

      &.completed, &.current, &.disabled {
        &::before {
          border-left: 2px dotted rgba(75, 75, 80, 0.25);
        }
      }

      &.completed, &.current, &.disabled {
        &::after {
          @include config.brand-subtext-default-regular;
          color: #FFF;
          text-align: center;
        }
      }

      &.completed {
        &::after {
          border: 2px solid #03a0b5;
          background-color: #03a0b5;
        }
      }

      &.current {
        &::after {
          border: 2px solid green;
          background-color: green;
        }
      }
      
      &.disabled {
        &::after {
          border: 2px solid #d8d9da;
          background-color: #d8d9da;
        }
      }

      &:last-child::before {
        border-left: none;
      }
    }
}

.progress-summary-style {
  @include config.brand-subtext-default-regular;
  display: -webkit-box;
  margin: 0;
  padding-top: 5px;
  color: rgba(39, 71, 89, 0.6);
  word-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.step-header-style {
  @include config.brand-text-default-regular;
  color: rgba(39, 71, 89, 0.6);
}

.step-header-active-style {
  @include config.brand-text-lg-semibold;
}

// IPad and above
@media screen and (min-width: config.$breakpoint-md) {

  .progress-summary-style {
    @include config.brand-text-default-regular;
    padding-top: 5px;
    }

  .step-header-style {
    @include config.brand-header-sm;
  }

  .step-header-active-style {
    @include config.brand-header-sm;
  }
}
