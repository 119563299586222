@use './colors';

// Home Page
$home-container-desktop-background: url('../../assets/img/layout/group-4.svg') center bottom -100px no-repeat,
    url('../../assets/img/welcome_desktop.png') center no-repeat;

$home-container-mobile-background: url('../../assets/img/mobile/landing_screen.png') center top
    no-repeat;

// Company Select Page
$companySelect-container-background: url('../../assets/img/layout/group-4.svg') center bottom no-repeat,
linear-gradient(to bottom, #50c8d7, #50c8d7),
linear-gradient(28deg, #50c8d7, #2de8ff);

// Wrapper Hoc
$wrapper-hoc-background: url('../../assets/img/layout/group-4.svg') center bottom no-repeat,
linear-gradient(to bottom, #50c8d7, #50c8d7),
linear-gradient(28deg, #50c8d7, #2de8ff) !important;

// Coach card
$coach-details-background: linear-gradient(to right, rgba(255, 193, 0, 0.79), #ffc332);

// App Header
$nav-bar-active-hover-color: colors.$foreground-primary;

// Self-Use page
$selected-goal-banner-current-goal-label-background-color: #fff4d7;
