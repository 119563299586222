@use "sass:color";
@use "src/styles/config";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 12px 10px 12px 20px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}

.input-style {
  @include config.brand-text-default-regular;
  width: 100%;
  outline: none;
  border: 0;
  resize: none;
}

.input-style::placeholder {
  color: color.change(config.$brand-color-foreground-primary, $alpha: 0.4);
  font-weight: config.$brand-fw-semibold;
}

.send-button-style {
  cursor: pointer;
  background: none;
  border: 0;
  position: relative;
}

.send-button-style img {
  max-height: 22px;
}

.message-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.preview-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.media-container {
  border-radius: 30px;
}

.slider-section {
  width: 100%;
  padding: 0 20px 0 20px;
  position: relative;
  margin-bottom: 15px;
}

.slider-section span {
  @include config.brand-subtext-default-regular;
  border: 2px solid #44d5e7;
  border-radius: 8px;
  padding: 10px 20px;
  width: auto;
  display: flex;
  justify-content: left;
  margin: 0;
  height: 80px !important;
  overflow-y: auto;
  text-align: left;
}

.slider-section button {
  background: transparent;
  border: none;
  cursor: pointer;
}
