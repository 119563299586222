@use "src/styles/config";
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 67%;
  position: absolute;
  padding: 0 5%;
  top: 5%;
}

.context-style {
  width: 40%;
}

.heading-style {
  @include config.brand-header-xl;
  color: #fff;
}

.duration-style {
  @include config.brand-text-lg-regular;
  color: #fff;
  margin-bottom: 15px;
  opacity: 0.8;
}

.subheading-style {
  @include config.brand-text-lg-regular;
  color: #ffffff;
}

.toggle-button-text-style {
  @include config.brand-text-lg-regular;
  margin-left: 8px;
  color: config.$brand-color-foreground-primary;
}

.toggle-to-transcript-button-style {
  display: inline-flex;
  padding: 7px 25px;
  border-radius: 20px;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: config.$brand-fw-bold;
  z-index: 100;
}

@media only screen and (max-width: 767px) {
  .container {
    flex-direction: column;
  }
  .context-style {
    width: 85%;
    color: #fff;
  }
  .heading-style {
    @include config.brand-header-xl; 
    color: #fff;
  }
  .subheading-style {
    @include config.brand-text-lg-regular;
    color: #fff;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .toggle-button-style {
    align-self: center;
  }
  .toggle-to-transcript-button-style {
    padding: 5px 20px !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .context-style {
    width: 35%;
  }
  .heading-style {
    font-size: config.$brand-fs-header-sm !important;
  }
  .subheading-style {
    font-size: config.$brand-fs-text-default !important;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    top: 5%;
  }
}
