@use "src/styles/config";

.retake-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.retake-survey-modal {
  text-align: center;
  padding: 25px;
}

.retake-survey-modal h2 {
  @include config.brand-header-lg;
  margin-top: 20px;
  text-align: center;
}

.retake-survey-modal p {
  @include config.brand-text-default-regular;
  margin: 20px auto 0 auto;
  text-align: center;
}

.reload-btn {
  width: 15em;
  height: 3em;
  margin-top: 30px;
  border-radius: 35px;
  background-color: config.$brand-color-secondary;
  color: #fff;
  font-weight: config.$brand-fw-bold;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
}
.close-icon-style {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1000;
}

@media screen and (max-width: 991px) {
  .retake-survey-modal p {
    width: 100%;
  }

  .retake-survey-modal {
    text-align: center;
    padding: 25px 0px;
  }

  .retake-survey-modal p {
    @include config.brand-text-lg-regular;
  }
}
