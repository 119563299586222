@use "sass:color";
@use "src/styles/config";

.step-progress-container {
  position: relative;
  padding-left: 45px;
  list-style: none;
  
  &::before {
    display: flex;
    content: '';
    position: absolute;
    top: 10px;
    left: 25px;
    width: 10px;
    height: 90%;
    border-left: 2px solid #CCC;
  }
}


.step-progress-item {
    position: relative;
    counter-increment: list;
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 10px;
      left: -25px;
      width: 12px;
      height: 12px;
      border: 2px solid #CCC;
      border-radius: 50%;
      background-color: #FFF;
    }
  }
.active {
    &::before {
      border-left: 2px solid rgb(216,216,216);
    }
    &::after {
      content: "";
      @include config.brand-subtext-default-regular;
      color: #FFF;
    }
  }

.step-style {
  display: flex;
  flex-direction: column;
}
.title-style {
  @include config.brand-text-lg-regular;
  color: rgba(39, 71, 89, 0.6);
}
.progress-style {
  @include config.brand-subtext-default-regular;
  letter-spacing: normal;
  color: rgba(39, 71, 89, 0.6);
}
.steps-contanier {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
}
.edit-icon {
  color: color.change(config.$brand-color-foreground-primary, $alpha: 0.25);
  margin-right: 20px;
}

.wrapper {
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 10px 0 12px 0;
  height: inherit;
}

.container {
  position: relative;
  padding: 0 20px 0 20px;
}

.subheader-style,
.description-style {
  position: relative;
}

.subheader-style {
  @include config.brand-header-lg;
}

.description-style {
  @include config.brand-text-lg-regular;
  position: relative;
  margin: 10px 0 20px 0;
}

.checklist-container {
  margin: 0 3% 0 3%;
}

.steps-container {
 display: flex;
 margin-top: 35px;
 justify-content: space-evenly;
}

.options-limit-text-style {
  @include config.brand-subtext-default-regular;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  color: config.$brand-color-alert;
}

// Smaller devices
@media screen and (max-width: config.$breakpoint-md) {
  .subheader-style,
  .description-style {
    width: 100%;
  }
}

// IPad and above
@media screen and (min-width: config.$breakpoint-md) {
  .container {
    padding: 0 10% 0 10%;
  }

  .steps-checklist-container {
    width: 100%;
  }

  .checklist-container {
    margin: 0 10% 0 10%;
  }
}
