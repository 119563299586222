@use "src/styles/config";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  touch-action: none;
  background-color: rgb(247, 247, 247);
}

.chat-container {
  width: 45rem;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loading {
  height: 100%;
  width: 100%;
  background: #ffffff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  border-radius: 15px 15px 0 0;
}

.loading-text-style {
  color: rgba(0, 0, 0, 0.65);
  @include config.brand-text-lg-regular;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}

@media screen and (max-width: config.$breakpoint-xl) {
  .container {
    background-color: config.$brand-color-background-primary-lighter;
    padding-top: 1px;
  }
  .chat-container {
    width: 100%;
  }
}

@media screen and (max-width: config.$breakpoint-md) {
  .container {
    border-radius: 0;
    width: 100vw;
  }
}
