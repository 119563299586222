@use "src/styles/config";

.container {
    position: relative;
    height: 100%;
    width: 100%;
}

.video-wrapper {
    position: relative;
    height: inherit;
}

.backdrop-style {
    filter: brightness(0.3);
}

.video-screen-style {
    width: 100% !important;
    height: 100% !important;
}

.audio-player-style {
    display: none;
}

.normal-video-screen {
    position: relative;
    height: 100%;
    bottom: 10px;
}

.max-video-screen {
    height: 100vh;
}

.video-screen-style video {
    background: #000000e0;
    width: 100% !important;
}

.mid-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;
    z-index: 100;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 100%;
    border: 1px solid;
    background-color: #494a4a;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
}

.mid-icon svg {
    font-size: 2.5rem;
}

.player-controls-pos {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 101;
}

.player-controls-pos-fullscreen {
    bottom: 10px;
}

.clickable-style {
    cursor: pointer;
}


@media only screen and (max-width: config.$breakpoint-xl) {
    .normal-video-screen {
        bottom: 75px;
    }
}
