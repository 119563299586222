@use "src/styles/config";

.radio-button {
  @include config.brand-subtext-default-semibold;
  min-height: 40px;
  height: auto;
  margin-top: 3px;
  text-align: center;
  width: 100%;
  border-radius: 30px !important;
  border: solid 1px config.$brand-color-primary;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: config.$brand-color-primary;
  margin-bottom: 8px;
  padding-top: 4px;
  line-height: inherit;
  align-items: center;
  justify-content: center;
  display: flex;
}

.radio-button:first-child {
  border-left: solid 1px config.$brand-color-primary;
}

.radio-button:not(:first-child)::before {
  background-color: transparent;
}

.radio-button:hover {
  background-color: config.$brand-color-primary;
  color: #fff !important;
}

.ant-radio-button-wrapper:first-child {
  border-left: 0px solid config.$brand-color-primary;
}

.radio-button:first-child {
  color: config.$brand-color-primary;
}

@media (hover: none) {
  .radio-button:hover {
    background-color: config.$brand-color-primary;
  }
}
