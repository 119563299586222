@use "sass:color";
@use "src/styles/config";

.text-style {
    @include config.brand-subtext-default-semibold;
    width: 100%;
}
 
.header-style {
    @include config.brand-subtext-default-semibold;
    line-height: 1.38;
    margin-top: 2px;
    margin-bottom: 0;
}

.big-card-chat-content-style h4{
    margin-top: 20px;
}

@media screen and (max-width: config.$breakpoint-xl) and (min-width: config.$breakpoint-md) {
    .text-style {
        @include config.brand-subtext-default-regular;
    }

    .header-style { 
        @include config.brand-subtext-default-regular;
    }
}
