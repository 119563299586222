@use 'sass:color';
@use 'src/styles/config';

.top-container {
  display: flex;
  flex-direction: column;
}
.container {
  background-color: config.$brand-color-background-primary-lighter;
  display: flex;
  height: 122px;
  overflow: hidden;
  margin-left: 4px;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.58),
    0 0 6px 0 rgba(99, 102, 106, 0.1), 0 3px 4px 0 rgba(99, 102, 106, 0.14);
}

.image-container {
  min-width: 340px;
  width: calc(560px + min(1px, 20vw - 400px));
  display: flex;
}

.image-style {
  max-width: 700px;
  object-position: min(1px, 20vw - 400px);
  min-height: 122px;
}

@mixin label-style {
  @include config.brand-subtext-default-regular;
  text-transform: uppercase;
  border-radius: 3px;
  width: max-content;
  @media (max-width: config.$breakpoint-md) {
    font-size: config.$brand-fs-mobile-subtext-default;
  }
}

.current-goal-text-style {
  @include label-style;
  padding: 2px 8px;
  background-color: config.$brand-selected-goal-banner-current-goal-label-background-color;
}

.goal-description-container {
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
}

.current-goal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 7px;
}

.session-text-style {
  @include label-style;
  color: config.$brand-color-foreground-primary;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 1px 15px;
}

.session-style {
  margin-right: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.current-session-style {
  @include config.brand-header-xl;
}

.goal-name-container-style {
  display: flex;
  flex: 1;
  align-items: center;
}

.goal-name-style {
  @include config.brand-header-lg;
  text-align: center;
}
.container-text-style {
  @include config.brand-header-sm;
  padding-bottom: 10px;
  padding-left: 4px;
  font-weight: normal;
}

.total-sessions-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.total-session-text-style {
  margin-top: 17px;
}

@media screen and (max-width: 991px) {
  .image-container {
    min-width: 200px;
    max-width: 200px;
  }

  .image-style {
    object-position: 0;
  }
}

@media screen and (max-width: 767px) {
  .image-container {
    min-width: 150px;
    max-width: 150px;
  }

  .image-style {
    object-position: -32px;
  }

  .container-text-style {
    padding-left: 16px;
  }

  .container {
    margin-left: 0;
    height: 100px;
    box-shadow: 0 2px 2px 0
        color.change(config.$brand-color-foreground-secondary, $alpha: 0.14),
      0 0 4px 0
        color.change(config.$brand-color-foreground-secondary, $alpha: 0.1),
      inset 0 1px 1px 0 rgba(255, 255, 255, 0.58);
  }

  .goal-name-style {
    @include config.brand-header-sm;
    line-height: 1.2rem;
    font-weight: config.$brand-fw-bold;
  }

  .current-session-style {
    font-size: config.$brand-fs-header-xl;
  }

  .session-style {
    margin-right: 10px;
  }
}
