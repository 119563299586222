@use "sass:color";
@use "src/styles/config";

.carousel-container {
  display: flex;
  flex-direction: column;
}

.container-text-style {
  @include config.brand-header-sm;
  font-weight: normal;
  padding-bottom: 10px;

}
