@use 'src/styles/config';

.container {
  max-width: 400px;
  border-radius: 4px !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 6px 0 rgba(99, 102, 106, 0.1),
    0 3px 4px 0 rgba(99, 102, 106, 0.2) !important;
}

.content-container {
  display: flex;
  height: 180px;
}

.content-container img {
  height: 100%;
  width: 100px;
  object-fit: cover;
}

.content-container div {
  padding: 10px 20px 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.content-container h2 {
  @include config.brand-header-default;
}

.content-container p {
  color: config.$brand-color-foreground-primary;
  text-overflow: ellipsis;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  padding: 10px 15px;
  border-top: 1px solid rgba(151, 151, 151, 0.4);
}

@media only screen and (max-width: 767px) {
  .content-container h2 {
    @include config.brand-header-sm;
  }
}
