@use "src/styles/config";

.header-container {
  text-align: center;
  margin: 0;
  background: config.$brand-color-background-primary-light;
  padding: 20px;
}

.header-container h2 {
  @include config.brand-header-lg;
}

.form-container {
  padding: 20px 40px;
}

@media screen and (max-width: 767px) {
  .form-container {
    padding: 20px 20px;
  }
}
