@use "sass:color";
@use "src/styles/config";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  touch-action: none;
  position: relative;
  padding-bottom: env(safe-area-inset-bottom);
}

.spinner-container-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 75vh;
}

.spinner-text-style {
  margin-left: 1em;
}

.top-panel-style {
  background-color: config.$brand-color-background-primary-lighter;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 0;
  min-height: 2.5rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.screen-dots-style {
  display: inline-flex;
  padding-top: 5px;
}

.dot-style {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

.dot-style:nth-child(n + 2)::before {
  display: inline-block;
  content: '';
  border-top: 1px solid rgba(39, 71, 89, 0.27);
  width: 7px;
  transform: translate(-7px, -9px);
}

.active-dot-style {
  background-color: config.$brand-color-foreground-primary;
}

.inactive-dot-style {
  background-color: color.change(config.$brand-color-foreground-primary, $alpha: 0.5);
}

.mid-panel-style {
  height: calc(100vh - 190px);
  overflow: auto;
  position: relative;
  margin-top: 35px;
}

.mid-panel-media-screen-style {
  overflow: hidden;
  padding-bottom: 0;
}

.bottom-panel-style {
  position: absolute;
  bottom: env(safe-area-inset-bottom, 0px);
  display: flex;
  z-index: 100;
  border-top: solid 1px color.change(config.$brand-color-foreground-secondary, $alpha: 0.15);
  width: 100vw;
  height: 75px;
  background-color: #fff;
  align-items: center;
}

.bottom-panel-keyboard-open-style {
  bottom: 0;
}

.button-style {
  min-width: 7rem;
  height: 2.5rem;
  border-radius: 25px;
  font-weight: config.$brand-fw-bold;
  border: 2px solid #ef6f59;
}
.popup-button-style {
  border: 1px solid;
  border-radius: 5px;
  margin: 2px 5px;
}

@media screen and (max-width: config.$breakpoint-md) {
  .button-style {
    @include config.brand-subtext-default-regular;
    color: #fff;
    padding: 5px 20px;
    margin-right: 5px;
  }
}

@media screen and (max-width: config.$breakpoint-xl) {
  .mid-panel-style {
    position: relative;
    top: 0;
    height: 100%;
    bottom: 75px;
    padding-bottom: 75px;
  }

  .mid-panel-media-screen-style {
    padding-bottom: 0;
  }
}

.session-name-style {
  @include config.brand-text-default-regular;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-container {
  padding: 15px 4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
