.container {
  position: relative;
  padding-top: 20px;
}

.container-desktop-padding-top {
  padding-top: 100px;
}

.carousel-container {
  margin: 35px 10px 0 10px;
}

.bgcolor {
  padding: 0 10px;
  padding-bottom: 30px;
}

.sectiontwo {
  margin-top: 35px;
  padding: 10px 20px;
}

@media (max-width: 767px) {
  .bgcolor {
    padding: 0 20px;
  }
}
