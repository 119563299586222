@use "src/styles/config";

.container {
  background-color: config.$brand-color-background-neutral;
  padding: 13px 8px 6px 9px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.category-content-container {
  display: flex;
  align-items: center;
}

.category-name-style {
  @include config.brand-header-lg; 
  margin: 0 0 0 10px;
}
