.radio-field span:first-child {
  position: absolute;
  top: 100%;
  left: 50%;
}

.radio-field {
  text-align: center;
  margin-bottom: 30px;
}

.img {
  width: 60%;
  margin-left: 7%;
}

@media screen and (max-width: 991px) {
  .img {
    margin-left: 10%;
  }
}
