@use "src/styles/config";

.container {
  box-shadow: 0 0 8px 0 rgba(113, 109, 109, 0.3);
  border-radius: 5px;
  margin: 15px 0;
  text-align: center;
  width: 382px;
  min-height: 304px;
  align-self: flex-start;
  background: #fff;
  display: flex;
  flex-direction: column;
}

.body {
  background-image: url('../../../../../assets/img/dashboard/resultcard.png');
  height: fit-content;
  min-height: 200px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.body-content {
  height: 100%;
  margin-left: 115px;
  margin-right: 15px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.body-content h1 {
  @include config.brand-header-default;
  text-align: left;
  line-height:1.25rem;
  margin-right: 12px;
}

.body-content p {
  @include config.brand-subtext-default-regular;
  font-size: config.$brand-fs-text-default !important;
  line-height:1.25rem !important;
  text-align: left;
  margin-bottom: 10px;
}

.action-button {
  @include config.brand-text-sm-semibold;
  width: 100%;
  border: none;
  text-align: center;
  color: config.$brand-color-secondary;
  padding: 15px;
  display: block;
  border-top: 2px solid #f4f4f4;
  background: #fff;
  text-decoration: underline;
  cursor: pointer;
  border-radius: 0 0 4px 4px;
}

@media screen and (max-width: 767px) {
  .container {
    min-height: 150px;
    width: 60%;
  }

  .body-content {
    margin-left: 25%;
  }
}

@media screen and (max-width: 580px) {
  .container {
    min-height: 150px;
    width: 100%;
  }

  .body-content {
    margin-right: 4%;
    margin-left: 37%;
  }

  .body-content h1 {
    font-size: config.$brand-fs-subtext-default !important;
  }

  .body-content p {
    @include config.brand-subtext-default-regular;
    font-size: config.$brand-fs-subtext-default !important;
    line-height: normal;
  }
}

@media screen and (max-width: 360px) {
  .body-content {
    margin-left: 42%;
    margin-right: 1%;
  }

  .body-content h1 {
    @include config.brand-header-sm;
  }

  .body-content p {
    @include config.brand-subtext-default-regular;
  }
}
