@use 'sass:color';
@use 'src/styles/config';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  bottom: 16px;
  margin: 16px 20px 14px 4px;
  box-shadow: 0 0 6px 0 rgba(99, 102, 106, 0.1),
    0 3px 4px 0 rgba(99, 102, 106, 0.14) !important;
}
.no-progress-style {
  @include config.brand-text-default-regular;
  margin: auto;
  text-align: center;
  display: flex;
  padding-right: 3px;
}

.tooltip-container-style {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 32px;
}

.number-style {
  @include config.brand-subtext-default-regular;
  position: relative;
  bottom: 6px;
  left: 8px;
  background: rgb(31, 71, 91);
  color: white;
  height: 17px;
  width: 17px;
  margin-top: 5px;
  text-align: center;
  border-radius: 20px;
}

.image-style {
  position: relative;
  bottom: 6px;
  left: 1px;
  height: 29px;
}

.chart-style {
  display: flex;
}

.subheader-style {
  @include config.brand-header-default;
  text-transform: lowercase;
}

.left-panel-style {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-style {
  @include config.brand-header-default;
}

.canvas-container {
  position: relative;
  display: flex;
  flex: 1 1;
  height: auto;
  width: auto;
  padding: 8px;
}

.description-style {
  margin-top: 15px;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 98%;
    flex-direction: column;
    bottom: 25px;
    margin-top: 25px;
  }

  .left-panel-style {
    width: 100%;
  }

  .description-style {
    text-align: center;
    margin-top: 0;
  }

  .subheader-style {
    @include config.brand-text-default-regular;
    text-align: center;
  }

  .header-style {
    @include config.brand-text-default-regular;
    text-align: center;
  }

  .tooltip-container-style {
    bottom: 1em;
  }
  .no-progress-style {
    @include config.brand-text-default-regular;
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .container {
    width: 100%;
    max-height: 500px;
  }
  .no-progress-style {
    padding: 15px;
  }
}
