@use "sass:color";
@use "src/styles/config";

.self-harm-container {
  text-align: center;
  padding: 10px 40px 10px 40px;
}

.self-harm-text-style {
  color: color.change(config.$brand-color-foreground-primary, $alpha: 0.8);
}

.self-harm-phone-container {
  font-weight: config.$brand-fw-bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.self-harm-phone-container .self-harm-phone  {
  @include config.brand-header-default; 
  color: config.$brand-color-secondary;
  letter-spacing: 2px;
  margin-left: 4px;
}

.self-harm-phone-container span
{
  color: config.$brand-color-secondary; 
}

.self-harm-button {
  margin-top: 15px;
}

.important-button-container {
  text-align: right;
}

.expand-icon-style {
  border: 1px solid config.$brand-color-primary;
  border-radius: 50%;
  margin-left: 3px;
}

.confirm-button {
  padding-left: 30px;
  padding-right: 30px;
}

.confirm-text-style {
  @include config.brand-subtext-default-regular;
  margin-top: 20px;
  color: rgb(139, 141, 144);
}

.hr-style {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid color.change(config.$brand-color-foreground-secondary, $alpha: 0.2);
  margin: 1em 0;
  padding: 0;
}
