@use "sass:color";
@use "src/styles/config";

.container {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 5% auto;
    background: #fff;
    max-width: 650px;
    border-radius: 6px;
    padding: 40px;
}

.top-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.top-container .card-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 3px 4px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.14), 0 0 6px 0 color.change(config.$brand-color-foreground-secondary, $alpha: 0.1);
    height: 125px;
    width: 165px;
    margin: 12px;
    cursor: pointer;
}

.option-text-style {
    @include config.brand-text-lg-regular;
    margin-top: 7px;
    text-align: center;
}

.card-style:hover .option-text-style {
    font-weight: config.$brand-fw-semibold;
}

.logout-button-style {
    width: 15rem;
    height: 2.500rem;
    border-radius: 25px;
}

.bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.app-version-container {
    width: fit-content;
    margin-top: 20px;
}

.app-version-ruler-style {
    margin: 6px;
    opacity: 0.27;
    border-width: 1px;
    color: #000
}

.app-version-style {
    @include config.brand-subtext-default-regular;
}

.terms-container {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    top: 25px;
}

.terms-text-style {
    @include config.brand-subtext-default-semibold;
    text-decoration: underline;
    cursor: pointer;
}

.terms-text-style:hover {
    text-decoration: underline;
}

@media screen and (max-width: 680px) {
    .container {
      max-width: 310px;
    }
}
