@use "sass:color";
@use "src/styles/config";

.chat-center-container {
  display: flex;
  flex-flow: column;
  height: inherit;
}

.container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
}

.client-info-container {
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  background: #fff;
  min-height: 60px;
  justify-content: space-between;
}

.client-details-container {
  display: flex;
  align-items: center;
  width: 90%;
}

.client-details-container h5 {
  @include config.brand-header-default;
  text-transform: capitalize;
  margin: 0 0 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
  color: #66686c;

}

.client-details-container span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  background: #f7f7f7;
  text-transform: uppercase;
  font-weight: config.$brand-fw-bold;
  color: config.$brand-color-foreground-primary;
}

.client-info-button-style {
  background: none;
  border: 0;
  cursor: pointer;
}

.info-icon-style {
  vertical-align: middle;
  color: color.change(config.$brand-color-foreground-primary, $alpha: 0.7);
}

.clients-empty-placeholder {
  margin-top: 25px;
  background: #fff;
  border-radius: 7px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 15px;
  box-shadow: 0 8px 6px -6px #00000059;
}

.input-container {
  padding: 6px 20px 10px;
  width: 100%;
  background: config.$brand-color-background-primary-lighter;
  bottom: 0;
  z-index: 0;
}

.no-clients-assigned-container {
  text-align: center;
  margin: 40px;
  padding: 15px 20px;
  border: 1px solid grey;
  border-radius: 10px;
  max-width: 450px;
}

.client-initial-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 15px;
}
