@use "src/styles/config";

.assessment-header {
  line-height: 1.2;
  text-align: center;
  vertical-align: middle;
  height: 150px;
  background: url('../../../../assets/img/desktop/assessment/assessment_header.svg')
    no-repeat bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assessment-header-text {
  @include config.brand-header-lg;
  font-style: normal;
  font-stretch: normal;
  color: #ffffff;
  padding: 0 1px 0 1px;
}

@media screen and (max-width: 600px) {
  .assessment-header {
    background: url('../../../../assets/img/mobile/assessment/assessment_header.png')
      no-repeat bottom;
    background-size: cover;
  }
}
