@use "sass:color";
@use "src/styles/config";

.container {
  position: relative;
  text-align: left;
  background: #fff;
  border-radius: 6px;
  padding: 30px 20px;
  width: 98%;
  max-width: 350px;
  margin: auto;
}

.button-outer {
  text-align: center;
}

.company-select-button {
  margin: 20px auto 0;
  width: 160px;
}

.form-field {
  width: 100%;
}

.heading-text {
  @include config.brand-header-lg;
  text-align: left;
}

.disclaimer-style {
  @include config.brand-text-sm-regular;
  width: 100%;
  margin-top: 45px;
}

.privacy-notice {
  margin-top: 20px;
}

@media screen and (min-width: config.$breakpoint-md) {
  .container {
    padding: 45px 32px;
    max-width: 472px;
    margin: 2rem auto;
  }
}

