@use "src/styles/config";

@font-face {
  src: url('../assets/fonts/ProximaNovaRegular.otf');
  font-family: 'ProximaNova';
  font-weight: 400;
}

@font-face {
  src: url('../assets/fonts/ProximaNovaSemibold.otf');
  font-family: 'ProximaNova';
  font-weight: 600;
}

@font-face {
  src: url('../assets/fonts/ProximaNovaBold.otf');
  font-family: 'ProximaNova';
  font-weight: 700;
}

@font-face {
  src: url('../assets/fonts/ProximaNovaExtrabold.otf');
  font-family: 'ProximaNova';
  font-weight: 800;
}

body {
  margin: 0;
  padding: 0;
  font-family: config.$brand-ff-default !important;
  font-size: 16px !important; // * root font size
}

div {
  outline: none;
}

.ant-select-selection {
  background: transparent !important;
  border: none !important;
}

.ant-select {
  color: #ffffff !important;
}

.ant-select-dropdown-menu-item {
  color: config.$brand-color-foreground-primary !important;
  background-color: #fff !important;
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-active {
  background-color: #f5f5f5 !important;
}

.ant-select-auto-complete.ant-select .ant-input:focus,
.ant-select-auto-complete.ant-select .ant-input:hover {
  border: none !important;
}

.disable-scroll-style {
  overflow: hidden;
}

.ant-alert-icon {
  position: static !important;
  margin-right: 5px !important;
}

@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 940px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: table;
}

.modal-video-inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -35px;
  right: -35px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}

.modal-video-close-btn:before {
  transform: rotate(45deg);
}

.modal-video-close-btn:after {
  transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

.MuiInputBase-input-46 {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  margin: 0;
  padding: 6px 0 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  -webkit-tap-highlight-color: transparent;
}

.MuiInputBase-inputType-49 {
  height: 1.1875em;
}

.MuiSelect-selectMenu-57 {
  width: auto;
  height: auto;
  overflow: hidden;
  min-height: 1.1875em;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.MuiFormLabel-root-16 {
  @include config.brand-text-default-regular;
  padding: 0;
  font-family: config.$brand-ff-default;
  line-height: 1;
}

.MuiSelect-icon-59 {
  top: calc(50% - 12px);
  right: 0;
  color: #fff !important;
  position: absolute;
  pointer-events: none;
}

.MuiSelect-icon-79 {
  top: calc(50% - 12px);
  right: 0;
  color: #fff !important;
  position: absolute;
  pointer-events: none;
}

.ant-card-wider-padding .ant-card-body {
  padding: 15px 32px !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: block;
  top: 0;
  left: 0;
  width: 0 !important;
  height: 0 !important;
  position: absolute;
  background-color: white;
}

#root {
  height: 100%;
}

:root {
  --ion-font-family: config.$brand-ff-default;
}

ion-content {
  --keyboard-offset: 0px !important;
  --background: none;
}

ion-tab-button, ion-tab-bar, ion-toolbar {
  --background: #fff;
}

_:-ms-fullscreen,
:root div {
  flex-basis: auto;
}

#chat-widget-container {
  max-height: calc(100vh - env(safe-area-inset-top)) !important;
}
