@use "src/styles/config";

.back-drop-container {
  z-index: 103;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.player-style video:focus {
  outline: none !important;
}

.player-style video {
  height: auto !important;
}

.container {
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 0 5px 0 5px;
  align-items: center;
  justify-content: center;
}

.close-button-container {
  position: absolute;
  align-self: flex-end;
}

.close-button {
  position: relative;
  font-size: 2.875rem !important;
  left: 40px;
  bottom: 40px;
  cursor: pointer;
  color: #fff;
}

.player-container {
  display: flex;
  flex-direction: column;
}

.retry-button-container {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.retry-text-style {
  color: #fff !important;
}

@media screen and (max-width: config.$breakpoint-md) and (orientation: portrait) {
  .player-style {
    width: 100% !important;
    height: 30vh !important;
  }

  .close-button {
    left: 10px;
  }
  .player-container {
    width: 100%;
  }
}

@media screen and (min-width: config.$breakpoint-md) and (max-width: config.$breakpoint-xl) and (orientation: portrait) {
  .player-style {
    height: 42vh !important;
    width: 99vw !important;
  }
  .close-button {
    left: 8px;
  }
}

@media screen and (max-height: config.$breakpoint-sm) and (orientation: landscape) {
  .close-button {
    bottom: 10px;
    left: 40px;
  }
}
