@use "src/styles/config";

.expansion-panel-container {
  margin: 8px;
  width: 100%;
}

.shadow-style {
  box-shadow: 3px 3px 15px 1px hsla(0, 4%, 89%, 0.7);
}

.tool-name-style {
  @include config.brand-subtext-default-semibold;
}
