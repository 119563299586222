@use "src/styles/config";

.radio-rating {
  border: 1px solid config.$brand-color-primary;
  border-radius: 25px !important;
  margin-top: 10px;
  margin-left: 15px;
  color: #000;
  background-color: #fff;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 0;
}

.radio-rating span {
  margin-top: 3px;
}

.radio-rating:hover {
  background-color: config.$brand-color-primary;
  color: #fff;
}

.radio-rating:before {
  background-color: transparent !important;
}

.radio-rating:first-child {
  border-left: 1px solid #3ab54a !important;
}
