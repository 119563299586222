@use "src/styles/config";

.container {
  @include config.brand-subtext-default-regular;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 10px;
  text-align: center;
}

.chat-list-error-text {
  @include config.brand-header-lg;
  margin-bottom: 15px;
}

.error-text-coach-card {
  color: #fff;
  margin-top: unset;
  margin-bottom: unset;
  padding: 0 10px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.chat-list-retry {
  @include config.brand-text-lg-semibold;
  border: none;
  padding: 5px 25px;
  border-radius: 50px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-image: linear-gradient(279deg, config.$brand-color-primary, #61c6d7),
    linear-gradient(to bottom, config.$brand-color-primary, config.$brand-color-primary) !important;
}

.container-coach-error {
  @include config.brand-subtext-default-regular;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 75px;
  border-radius: 15px;
  background-image: linear-gradient(279deg, config.$brand-color-primary, #61c6d7),
    linear-gradient(to bottom, config.$brand-color-primary, config.$brand-color-primary) !important;
  color: #fff;
  justify-content: center;
}

.retry-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 0.2em;
  text-decoration: underline;
  cursor: pointer;
}

.collapse-icon-container {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}

.collapse-icon-style:hover {
  cursor: pointer;
}

.container-pagination-error {
  @include config.brand-subtext-default-regular;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 4px;
  background-color: config.$brand-color-background-primary-lighter;
  color: config.$brand-color-alert;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.error-text {
  color: config.$brand-color-alert;
  margin-left: 0.4em;
}

.coach-info {
  margin-left: 8px;
  flex: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.warning-icon {
  font-size: 1.05rem !important;
}
