.container {
  position: relative;
  padding-bottom: env(safe-area-inset-bottom);
  margin: 10px 15px 10px 15px;
}

.title-style, .copyright-text-style {
  text-align: center;
}

