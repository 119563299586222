@use "src/styles/config";

.full-width {
    width: 100%;
}

.field-input .MuiFormLabel-root-16 {
    @include config.brand-text-default-regular;
    color: rgba(255, 255, 255, 0.54);
    padding: 0;
    line-height: 1;
}

.submit-button-style {
    height: 40px;
    margin: 40px auto 20px auto;
    padding: 15px 45px;
}