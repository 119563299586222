@use "src/styles/config";

.container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  flex: 1;
  width: 100%;
}

.thumbnail-style {
  flex: 3;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px 5px 0 0;
}

.footer-style {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  padding: 5px 7px;
  align-items: center;
}

.footer-white-background {
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
}

.tags-style {
  @include config.brand-subtext-default-regular;
  color: #000;
}

.add-media {
  color: #ef6e59;
  cursor: pointer;
}

.upper-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header-style {
  @include config.brand-text-default-regular;
  color: rgba(255, 255, 255, 0.88);
  padding: 5px 5px 0px 5px;
}

.sub-header-style {
  @include config.brand-subtext-default-regular;
  flex: 1;
  color: rgba(255, 255, 255, 0.87);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.lower-container {
  @include config.brand-subtext-default-regular;
  flex: 2;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.87);
  padding-left: 5px;
  padding-right: 5px;
}

.sub-header-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
}

.play-icon {
  color: rgba(255, 255, 255, 0.87);
  font-size: 1.875rem !important;
  cursor: pointer;
  flex: 1;
}

.media-duration {
  margin-top: 20px;
  color: #fff;
}

.selected-media-icon {
  background-color: #54c8d7;
  color: #fff;
  border-radius: 3px;
}

.highlight {
  border: 1.5px solid #54c8d7;
}

.play-button-style {
  align-self: flex-end;
  background: none;
  border: none;
  padding: 0;
}
