@use "src/styles/config";

.loader-outer {
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  z-index: 99999;
  flex-direction: column;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
}

.img-box {
  max-width: 100px;
  max-height: 35px;
  margin: 0 auto;
  display: inline-block;
}

.loader-box {
  width: 50px;
  margin: 30px 0;
}

.loader-text {
  @include config.brand-text-default-regular;
  color: rgba(0, 0, 0, 0.65);
}
